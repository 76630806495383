export const colors = {
  primaryColor: '#62933D', // Dark Green
  softGreen: '#4B7A3559', // Soft Green
  background: '#F5FFF1', // Ligth Green
  text: '#707070', // Gray
  gray: '#CDD6DE', // Gray 2
  grey: '#B2BECB', // Gray 3
  transparentGray: '#00000061', // Transparent Gray
  shadow1: '#00000026', // Shadow 1 Ligth
  shadow2: '#00000029', // Shadow 2 Less Ligth
  white: '#FFFFFF', // White
  red: '#B23333', // Alerts and Warnings
  pomegranate: '#F53723', // Pomegranate
  orange: '#F99E23', // Transparent
  yellow: '#F3D540', // Transparent
  mantis: '#8ACF63', // Mantis
  coyoteBrown: '#7B6739', // Coyote Brown
  moonstoneBlue: '#67B1C8', // Moonstone Blue
  floral: '#A086DE', // Floral
  transparent: 'transparent', // Transparent
};
