import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from './AlertInfo.styles';

const AlertInfo = ({ description, image }) => {
  const { t } = useTranslation();
  return (
    <Alert>
      <p className="header">{t('alert')}</p>
      <p className="body">{description}</p>
      {image && <img src={image} alt="alert-img" />}
    </Alert>
  );
};

export default AlertInfo;
