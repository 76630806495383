import React from 'react';
import { Close, Container, Modal } from './PopUp.styles';
import closeIcon from '../../assets/icons/close.svg';

const PopUp = (props) => {
  const { children, open, close } = props;

  if (open) {
    return (
      <Container styles={open ? 'display: block;' : 'display: none'}>
        <Modal>
          {close && <Close src={closeIcon} onClick={() => close()} />}
          {children}
        </Modal>
      </Container>
    );
  }
  return null;
};

export default PopUp;
