import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .label {
      font-size: 16px;
      font-weight: 600;
      margin-top: auto;
      margin-bottom: auto;
      color: ${colors.text};
    }
  }
`;
