// GENERAL STATUSES
export const SUCCESS = 'success';
export const ERROR = 'error';

// REGEX
export const REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

// MEDIA TYPES
export const IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
];

// HTTP STATUS
export const HTTP_STATUS = {
  UNHAUTORIZED: 401,
  FORBIDDEN: 403,
  SUCCESS: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_ACCEPTED: 202,
  ERROR: 500,
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const EVALUATION_STATUS = {
  TO_EVALUATE: 'TO_EVALUATE',
  IN_PROGRESS: 'IN_PROGRESS',
  EVALUATED: 'EVALUATED',
  IN_EVALUATION: 'em_avaliacao',
};

export const VISIBILITY_OPTIONS = {
  VERY: 'VERY',
  LITTLE: 'LITTLE',
};

export const FORM_D32_PARAMETERS = {
  DOMINANT: 'DOMINANT',
  BUSHES: 'BUSHES',
  COVERAGE_LEVEL: 'COVERAGE_LEVEL',
};

export const FORM_D33_PARAMETERS = {
  SLITS: 'SLITS',
  SUCCULENT_PLANTS: 'SUCCULENT_PLANTS',
  FETUSES: 'FETUSES',
  MOSSES: 'MOSSES',
  LICHENS: 'LICHENS',
};

export const FORM_D34_PARAMETERS = {
  N_VEGETATION: 'N_VEGETATION',
  SELECTION_VEGETATION: 'SELECTION_VEGETATION',
  SELECTION_DEGRADATION: 'SELECTION_DEGRADATION',
};

export const FORM_D35_PARAMETERS = {
  BUSHES: 'BUSHES',
  VEGETATION: 'VEGETATION',
  SELECTION_VEGETATION: 'SELECTION_VEGETATION',
  SELECTION_SLOPE: 'SELECTION_SLOPE',
};

export const FORM_D36A_PARAMETERS = {
  VEGETATION: 'VEGETATION',
  RIPARIAN_GALLERY_COVERAGE: 'RIPARIAN_GALLERY_COVERAGE',
};

export const FORM_D36B_PARAMETERS = {
  VEGETATION: 'VEGETATION',
  LIANAS: 'LIANAS',
  LIANAS_STRATUM: 'LIANAS_STRATUM',
  VEGETATION_COVERAGE: 'VEGETATION_COVERAGE',
};

export const D32_COVERAGE_OPTIONS = [
  { label: '>75%', value: 1 },
  { label: '50 a 75%', value: 2 },
  { label: '25 a 50%', value: 3 },
  { label: '<25%', value: 4 },
];

export const D34_VEGETATION_OPTIONS = [
  { label: '<50%', value: 1 },
  { label: '50 a 75%', value: 2 },
  { label: '>75%', value: 3 },
];

export const D34_DEGRADATION_OPTIONS = [
  { label: '>50%', value: 1 },
  { label: '25 a 50%', value: 2 },
  { label: '<25%', value: 3 },
];

export const D35_VEGETATION_OPTIONS = [
  { label: '<50%', value: 1 },
  { label: '50 a 75%', value: 2 },
  { label: '>75%', value: 3 },
];

export const D35_SLOPE_OPTIONS = [
  { label: '<25%', value: 1 },
  { label: '25 a 50%', value: 2 },
  { label: '>50%', value: 3 },
];

export const D36A_RIPARIAN_COVERAGE_OPTIONS = [
  { label: '<5%', value: 1 },
  { label: '5 a 25%', value: 2 },
  { label: '25 a 50%', value: 3 },
  { label: '>50%', value: 4 },
];

export const D36B_VEGETATION_COVERAGE_OPTIONS = [
  { label: '<25%', value: 1 },
  { label: '25 a 50%', value: 2 },
  { label: '50 a 75%', value: 3 },
  { label: '>75%', value: 4 },
];

export const INDICATOR_TABS = {
  SOLO: 'solo',
  REGENERACAO: 'regeneracao',
  PASTAGEM: 'pastagem',
};

export const OPTION_COLOR = {
  RED: 'red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
};

export const ANSWER_TYPE = {
  SB: 'SB',
  MA: 'MA',
  AR: 'AR',
  CT: 'CT',
  CP: 'CP',
  GT: 'GT',
  GP: 'GP',
};
