import styled from 'styled-components';
import { colors } from '../../constants/colors';

const ToggleBtn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;

  p {
    margin-bottom: 0;
  }

  label {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 14px;
    top: 4px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.transparentGray};
    transition: 0.3s;
    border-radius: 30px;
  }

  span:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: -2px
    bottom: 2.6px;
    background-color: ${colors.white};
    border-radius: 50%;
    transition: 0.3s;
    transform: translateX(15px);
    top: -3px;
    box-shadow: 1px 1px ${colors.transparentGray};
  }

  input:checked + span {
    background: ${colors.softGreen};
    &:before {
      background-color: ${colors.primaryColor};
    }
  }

  input:checked + span:before {
    transform: translateX(0px);
  }
`;

export default ToggleBtn;
