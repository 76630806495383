/* eslint-disable no-unused-vars */
import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  SUBPLOT_ANALYSYS_DATA,
  SUBPLOTS_IN_EVALUATION,
  SUBPLOTS_IN_EVALUATION_SUCCESS,
  SUBPLOTS,
  SUBPLOTS_SUCCESS,
  SUBPLOT,
  SUBPLOT_SUCCESS,
  UPDATE_SUBPLOT_AP_START,
  UPDATE_SUBPLOT_AP_SUCCESS,
  UPDATE_SUBPLOT_AP_ERROR,
  CREATE_SUBPLOT_POINT_START,
  CREATE_SUBPLOT_POINT_SUCCESS,
  CREATE_SUBPLOT_POINT_ERROR,
} from './types';
import { HTTP_STATUS } from '../../constants';

import { getAuthToken } from '../../services/local';

export const getSubPlotsInEvaluation = () => async (dispatch) => {
  dispatch({ type: SUBPLOTS_IN_EVALUATION });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const mockData = [
    { id: 1, name: 'SubParcela 1', checked: true, complete: 7, available: 7 },
    { id: 2, name: 'SubParcela 2', checked: false, complete: 4, available: 5 },
    { id: 3, name: 'SubParcela 3', checked: false, complete: 1, available: 4 },
  ];
  dispatch({
    type: SUBPLOTS_IN_EVALUATION_SUCCESS,
    subPlotsInEvaluation: mockData,
  });
  return Promise.resolve('Resolved');
};

export const getSubPlotsAnalysysData = () => async (dispatch) => {
  const mockData = {
    header: 'Análise',
    description:
      'Observar o estrato herbáceo, incluindo zonas interior e exterior à projeção da copa das árvores.',
    subtitle: 'Legenda',
    scaleInfo: [
      {
        color: 'red',
        header: 'Muito Baixo',
        description:
          'Ausência ou presença residual de leguminosas e de “outras”. Dominância de gramíneas, sem diversidade.',
      },
      {
        color: 'orange',
        header: 'Baixo',
        description:
          'Ausência ou presença residual de leguminosas. Presença de “outras”, com dominância de gramíneas. Com diversidade nos grupos de gramíneas e “outras”.',
      },
      {
        color: 'yellow',
        header: 'Moderado',
        description:
          'Ausência ou presença residual de leguminosas. Presença de gramíneas, com dominância de “outras”. Com diversidade nos grupos de gramíneas e “outras”.',
      },
      {
        color: 'green',
        header: 'Elevado',
        description:
          'Presença equilibrada dos 3 grupos (presença de leguminosas >5% de cobertura) e com diversidade em cada grupo. Nota: Se o grupo das leguminosas não tiver diversidade, a classificação passa a MODERADO.',
      },
    ],
  };
  dispatch({
    type: SUBPLOT_ANALYSYS_DATA,
    analysysData: mockData,
  });
  return Promise.resolve('Resolved');
};

export const getSubPlot = (id) => async (dispatch) => {
  dispatch({ type: SUBPLOT });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const tableData = [
    { col: 'identification', rowData: '123' },
    { col: 'area', rowData: '20ha' },
    { col: 'installment', rowData: 'parcelario' },
    { col: 'activities', rowData: 'atividades' },
    { col: 'ground', rowData: 'solo' },
    { col: 'trees', rowData: 'arvores' },
    { col: 'pasture', rowData: 'pastagem' },
    { col: 'sampling points', rowData: 'pontos amostragem' },
    { col: 'element points', rowData: 'pontos elementos' },
    { col: 'route', rowData: '1km' },
  ];

  const evaluationData = [
    { id: 1, date: '01/01/2023', complete: true },
    { id: 2, date: '02/01/2023', complete: false },
  ];
  const subplot = {
    name: 'Exploração',
    evaluatedCount: '1/2',
    tableData,
    evaluationData,
  };

  dispatch({
    type: SUBPLOT_SUCCESS,
    subplot,
  });
  return Promise.resolve('Resolved');
};

export const getSubPlots = (id) => async (dispatch) => {
  dispatch({ type: SUBPLOTS });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const subPlotsList = [
    {
      id: 1,
      name: 'SubPlot 1',
      access: { lat: 38.5360285073504, lng: -8.00468334252444 },
      date: '01/01/2023',
    },
    {
      id: 2,
      name: 'SubPlot 2',
      access: { lat: 38.5360285073504, lng: -8.00468334252444 },
      date: '01/01/2023',
    },
    {
      id: 3,
      name: 'SubPlot 3',
      access: { lat: 38.5360285073504, lng: -8.00468334252444 },
      date: '01/01/2023',
    },
    {
      id: 4,
      name: 'SubPlot 4',
      access: { lat: 38.5360285073504, lng: -8.00468334252444 },
      date: '01/01/2023',
    },
  ];
  dispatch({
    type: SUBPLOTS_SUCCESS,
    subplots: subPlotsList,
  });
  return Promise.resolve('Resolved');
};

export const updateSubplotAP = (plotId, values) => async (dispatch) => {
  dispatch({ type: UPDATE_SUBPLOT_AP_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    subplotId: plotId,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
  };

  try {
    const response = await axios.post(
      Endpoints.UPDATE_SUBPLOT_AP,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: UPDATE_SUBPLOT_AP_SUCCESS,
        property: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: UPDATE_SUBPLOT_AP_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const createSubplotPoint = (plotId, values) => async (dispatch) => {
  dispatch({ type: CREATE_SUBPLOT_POINT_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    subplotId: plotId,
    identification: values?.identification,
    area: values?.area,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
    pointTypeId: values?.pointTypeId,
  };

  try {
    const response = await axios.post(
      Endpoints.CREATE_SUBPLOT_POINT,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: CREATE_SUBPLOT_POINT_SUCCESS,
        property: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: CREATE_SUBPLOT_POINT_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
