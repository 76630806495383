import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  filter: drop-shadow(0 0 10px ${colors?.shadow1});
  color: ${colors?.text};
  gap: 10px;
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .label {
    font-size: 12px;
    opacity: 0.5;
  }
  .value {
    font-size: 14px;
  }
`;
