/* eslint-disable no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Page, Container, Card } from './UpdatePropertyAPScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import UpdatePropertyAPForm from '../../components/UpdatePropertyAPForm/UpdatePropertyAPForm';

const UpdatePropertyAPScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const savedProperties = useSelector((state) => state.savedData.properties);
  const propertyDetails = useSelector((state) => state.properties.property);
  const property =
    propertyDetails || savedProperties.find((item) => item.id === params?.id);

  const selectedPosition = location?.state?.selectedPos;

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={t('update_ap')}
        subtitle={property?.name}
      />
      <Container>
        <Card>
          <UpdatePropertyAPForm selectedPosition={selectedPosition} />
        </Card>
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default UpdatePropertyAPScreen;
