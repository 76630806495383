import React, { useCallback } from 'react';
import { InfoButton, LevelWrapper } from './LevelRate.styles';
import { OPTION_COLOR } from '../../constants';
import infoIcon from '../../assets/icons/TopBar/info.svg';

const LevelRate = ({ options, selectedLevel, onClick, setOpenHelpGuide }) => {
  const getColor = useCallback((name) => {
    switch (name) {
      case 1:
        return OPTION_COLOR?.RED;
      case 2:
        return OPTION_COLOR?.ORANGE;
      case 3:
        return OPTION_COLOR?.YELLOW;
      case 4:
        return OPTION_COLOR?.GREEN;
      default:
        break;
    }
  }, []);

  const handleOpenHelpGuide = (opt) => {
    setOpenHelpGuide({
      header: opt?.name,
      description: opt?.helpGuideDescription,
      color: getColor(opt?.value),
    });
  };

  return (
    <LevelWrapper>
      <div className="levelOptions">
        {options?.map((opt, index) => (
          <div key={index} className="option">
            <button
              type="button"
              onClick={() => onClick(opt?.value)}
              className={`${
                opt?.value === selectedLevel ? 'highlight' : ''
              } ${getColor(opt?.value)} optionContainer`}
            >
              {opt?.name}
            </button>
            <span className="description">{opt?.description}</span>
            <InfoButton type="button" onClick={() => handleOpenHelpGuide(opt)}>
              <img src={infoIcon} alt="info-img" />
            </InfoButton>
          </div>
        ))}
      </div>
    </LevelWrapper>
  );
};

export default LevelRate;
