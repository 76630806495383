import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT_START,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
} from './types';
import { HTTP_STATUS } from '../../constants';

import {
  clearLocalStorage,
  saveAuthToken,
  getAuthToken,
} from '../../services/local';

export const login = (username, password) => async (dispatch) => {
  dispatch({ type: AUTH_START });

  const body = {
    username,
    password,
  };

  try {
    const response = await axios.post(Endpoints.LOGIN, body);
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      saveAuthToken(response.data?.dataObject?.token);
      dispatch({
        type: AUTH_SUCCESS,
        user: username,
      });
      return Promise.resolve(response?.data?.message);
    }
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const logout = (username) => async (dispatch) => {
  dispatch({ type: LOGOUT_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const body = {
    username,
  };

  try {
    const response = await axios.post(Endpoints.LOGOUT, body, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({ type: LOGOUT_SUCCESS });
      clearLocalStorage();
      // eslint-disable-next-line no-undef
      window.location.replace(`${process.env.REACT_APP_URL}/`);
      return Promise.resolve(response?.data?.message);
    }
  } catch (error) {
    dispatch({ type: LOGOUT_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const recoverPassword = (email) => async () => {
  const body = {
    email: email?.trim(),
  };

  try {
    const response = await axios.post(Endpoints.RECOVER_PASSWORD, body);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.message);
    }
  } catch (error) {
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
