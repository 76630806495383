import styled from 'styled-components';
import { colors } from '../../constants/colors';

const InputStyle = styled.input`
  background-color: ${colors.white};
  height: 30px;
  min-height: 30px;
  width: 100%;
  padding-left: 10px;
  padding-right: ${(props) => (props.icon ? '40px' : '10px')};
  color: ${colors.text};
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }
  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.primaryColor};
    transition: border 400ms ease-out;
  }
`;

export default InputStyle;
