import {
  POINT_TYPE_INDICATORS,
  POINT_TYPE_INDICATORS_SUCCESS,
  POINT_TYPE_INDICATORS_ERROR,
} from '../actions/types';

const initialState = {
  pointTypeIndicators: [],
  loading: false,
};

const indicatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POINT_TYPE_INDICATORS:
      return {
        ...state,
        pointTypeIndicators: [],
        loading: true,
      };
    case POINT_TYPE_INDICATORS_SUCCESS:
      return {
        ...state,
        pointTypeIndicators: action.pointTypeIndicators,
        loading: false,
      };
    case POINT_TYPE_INDICATORS_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default indicatorsReducer;
