import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 35px;
`;

export const Properties = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-top: 30px;
`;
export const NoResultsLabel = styled.span`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;

export const Property = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
  background-color: ${colors.white};
  box-shadow: ${colors.shadow1} 0px 0px 10px;
  border-radius: 5px;
  cursor: pointer;
  align-items: flex-start;
`;

export const InfoWrapper = styled.div`
  .name {
    all: unset;
    color: ${colors.text};
    font-size: 18px;
    font-weight: 900;
  }
  .label {
    margin-top: 15px;
    color: ${colors.text};
    font-size: 14px;
    font-weight: 600;
    opacity: 50%;
  }
  .field {
    color: ${colors.text};
    font-size: 16px;
  }
  a {
    color: ${colors.primaryColor};
  }
`;

export const ActionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 auto auto;
  align-items: center;
  .click {
    margin-top: 60px;
    width: 12px;
    height: 28px;
  }
  button {
    background-color: ${colors.primaryColor};
    border: none;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    box-shadow: 0 0 5px ${colors.shadow2};
    img {
      height: 16px;
      width: 16px;
      display: flex;
      margin: auto;
    }
  }
`;
