import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  Container,
  Label,
  Error,
  Wrapper,
  InputWrapper,
} from './UpdateSubplotAPForm.styles';
import { colors } from '../../constants/colors';
import CustomInputFull from '../CustomInputFull/CustomInputFull';
import CustomButton from '../CustomButton/CustomButton';
import { getCurrentLocation } from '../../services/geolocation';
import saveIcon from '../../assets/icons/save.svg';
import { updateSubplotAP } from '../../store/actions/subplots';

const UpdateSubplotAPForm = ({ readOnly, selectedPosition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const subplotId = params?.plot_id;

  const [currentPosition, setCurrentPosition] = useState(null);
  const handleUpdateSubplotAP = useCallback(
    (values) => {
      dispatch(updateSubplotAP(subplotId, values));
    },
    [dispatch, subplotId],
  );

  useEffect(() => {
    if (selectedPosition) return setCurrentPosition(selectedPosition);
    getCurrentLocation().then((value) => setCurrentPosition(value));
  }, [selectedPosition]);

  const formik = useFormik({
    initialValues: {
      latitude: currentPosition?.lat,
      longitude: currentPosition?.lng,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      latitude: Yup.number().required(t('required')),
      longitude: Yup.number().required(t('required')),
    }),
    onSubmit: (values) => {
      handleUpdateSubplotAP(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {!currentPosition ? (
        <Wrapper>
          <Spinner animation="border" variant="dark" />
          <Label>{t('getting_coordinates')}</Label>
        </Wrapper>
      ) : (
        <Wrapper>
          <InputWrapper>
            <Label htmlFor="latitude">{t('latitude')}</Label>
            <CustomInputFull
              id="latitude"
              name="latitude"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.latitude}
              readOnly={readOnly}
            />
            {formik.touched.latitude && formik.errors.latitude ? (
              <Error>{formik.errors.latitude}</Error>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="longitude">{t('longitude')}</Label>
            <CustomInputFull
              id="longitude"
              name="longitude"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.longitude}
              readOnly={readOnly}
            />
            {formik.touched.longitude && formik.errors.longitude ? (
              <Error>{formik.errors.longitude}</Error>
            ) : null}
          </InputWrapper>
        </Wrapper>
      )}

      <CustomButton
        style={{
          marginTop: 'auto',
          width: '100%',
          borderRadius: '5px',
          marginBottom: 90,
        }}
        icon={saveIcon}
        text={t('save')}
        backgroundColor={colors.primaryColor}
        type="submit"
      />
    </Container>
  );
};

export default UpdateSubplotAPForm;
