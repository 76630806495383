import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from './LevelD36B.styles';
import {
  D36B_VEGETATION_COVERAGE_OPTIONS,
  FORM_D36B_PARAMETERS,
} from '../../../constants';
import { levelSuggestionD36B } from '../../../helpers/utils';
import PercentageRange from '../../PercentageRange/PercentageRange';
import ToggleButton from '../../ToggleButton/ToggleButton';

const LevelD36B = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();

  const levelD36BRef = useRef({
    vegetation: false,
    lianas: false,
    lianasStratum: false,
    vegetationCoverage: 0,
  });

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D36B_PARAMETERS.VEGETATION:
          levelD36BRef.current.vegetation = val;
          break;
        case FORM_D36B_PARAMETERS.LIANAS:
          levelD36BRef.current.lianas = val;
          break;
        case FORM_D36B_PARAMETERS.LIANAS_STRATUM:
          levelD36BRef.current.lianasStratum = val;
          break;
        case FORM_D36B_PARAMETERS.VEGETATION_COVERAGE:
          levelD36BRef.current.vegetationCoverage = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD36B(
          levelD36BRef?.current?.vegetation,
          levelD36BRef?.current?.lianas,
          levelD36BRef?.current?.lianasStratum,
          levelD36BRef?.current?.vegetationCoverage,
        ),
      );
    },
    [levelD36BRef, setLevel],
  );

  return (
    <FormWrapper>
      <div className="formRow">
        <span className="label">{t('form-d36b.vegetation')}</span>
        <ToggleButton
          id={`vegetation-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD36BRef?.current?.vegetation}
          onClick={(val) =>
            handleDataChange(val, FORM_D36B_PARAMETERS.VEGETATION)
          }
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d36b.lianas')}</span>
        <ToggleButton
          id={`lianas-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD36BRef?.current?.lianas}
          onClick={(val) => handleDataChange(val, FORM_D36B_PARAMETERS.LIANAS)}
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d36b.lianas-stratum')}</span>
        <ToggleButton
          id={`lianas-stratum-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD36BRef?.current?.lianasStratum}
          onClick={(val) =>
            handleDataChange(val, FORM_D36B_PARAMETERS.LIANAS_STRATUM)
          }
        />
      </div>
      <PercentageRange
        title={t('form-d36b.vegetation-coverage')}
        percentage={levelD36BRef.current.vegetationCoverage}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D36B_PARAMETERS.VEGETATION_COVERAGE)
        }
        options={D36B_VEGETATION_COVERAGE_OPTIONS}
      />
      <hr />
    </FormWrapper>
  );
};

export default LevelD36B;
