/* eslint-disable no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Container, Card } from './UpdatePointScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import ValidatedUpdatePointForm from '../../components/ValidatedUpdatePointForm/ValidatedUpdatePointForm';

const UpdatePointScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPosition = location?.state?.selectedPos;
  const pointName = location?.state?.pointName;

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={`${t('update')} ${pointName}`}
        subtitle={t('evaluation')}
      />
      <Container>
        <Card>
          <ValidatedUpdatePointForm selectedPosition={selectedPosition} />
        </Card>
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default UpdatePointScreen;
