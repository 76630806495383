import styled from 'styled-components';
import { colors } from '../../constants/colors';

const InputStyle = styled.input`
  background-color: ${colors.white};
  height: 40px;
  min-height: 40px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-image: ${(props) => `url(${props.icon})`};
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: right;
  color: ${colors.text};
  border: none;
  margin-top: 20px;
  border-radius: 5px;
  border-bottom: 1px solid ${colors.gray};
  filter: drop-shadow(0 0 10px ${colors?.shadow1});
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }
  &:focus {
    outline: none;
    transition: border 400ms ease-out;
  }
`;

export default InputStyle;
