import React from 'react';
import { Accordion, FormHeader, FormWrapper } from './CustomAccordion.styles';

const CustomAccordion = ({
  children,
  headerText,
  contentColor,
  id,
  expanded,
  onChange,
}) => (
  <Accordion>
    <input
      type="checkbox"
      id={`section${id}`}
      className="accordion__input"
      checked={expanded?.includes(id)}
      onChange={() => onChange(id)}
    />
    <FormHeader htmlFor={`section${id}`} color={contentColor}>
      {headerText}
    </FormHeader>
    <FormWrapper className="accordion__content" color={contentColor}>
      {children}
    </FormWrapper>
  </Accordion>
);

export default CustomAccordion;
