/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { extractPolyline } from '../../helpers/utils';
import 'leaflet/dist/leaflet.css';
import {
  Page,
  Container,
  ButtonContainer,
  SpinnerWrapper,
} from './PropertyMapScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import Plot from '../../data/property.kml';
import addIcon from '../../assets/icons/plus.svg';
import accessPointIcon from '../../assets/icons/access-point.svg';
import MapTooltip from '../../components/MapTooltip/MapTooltip';
import { getCurrentLocation } from '../../services/geolocation';
import {
  getProperty,
  resetStateProperty,
} from '../../store/actions/properties';

const PropertyMapScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const savedProperties = useSelector((state) => state.savedData.properties);
  const propertyDetails = useSelector((state) => state.properties.property);
  const property =
    propertyDetails || savedProperties.find((item) => item?.id === params?.id);
  // States for Map ( Map | Markers | Polygons)
  const [polyline, setPolyline] = useState(null);
  const [center, setCenter] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    dispatch(getProperty(params?.id));
  }, [dispatch, params]);

  useEffect(() => {
    if (!center?.length && property?.lat && property?.lng)
      setCenter([Number(property?.lat), Number(property?.lng)]);
  }, [center, property]);

  useEffect(() => {
    if (!currentLocation) {
      getCurrentLocation().then((value) =>
        setCurrentLocation([value?.lat, value?.lng]),
      );
    }
  }, [currentLocation]);

  // Markers: https://github.com/pointhi/leaflet-color-markers
  const customIcon = new Icon({
    iconUrl: accessPointIcon,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [38, 48],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const locationIcon = new Icon({
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  // Parses Kml local documents into Polyline to be rendered offline instead of using google API
  const parsePlot = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polyline from de Document
    fileReader.onload = async (e) => {
      const result = await extractPolyline(e.target.result);
      setPolyline(result?.polyline);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Runs Only once at the screen rendering, fetchs the local document and parses it
  useEffect(() => {
    fetch(Plot)
      .then((r) => r?.text())
      .then((text) => parsePlot(text));
  }, []);

  const backHandler = () => {
    dispatch(resetStateProperty());
    navigate(-1);
  };

  return (
    <Page>
      <TopBar back={backHandler} title={property?.name} />
      {currentLocation?.length ? (
        <Container>
          <MapContainer
            center={currentLocation}
            zoom={14}
            scrollWheelZoom={false}
            whenReady={(map) => {
              map.target.on('click', (e) => {
                navigate(
                  generatePath(routes.UPDATE_AP_PROPERTY.path, {
                    id: params?.id,
                  }),
                  { state: { selectedPos: e?.latlng } },
                );
              });
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {polyline && <Polyline positions={polyline} />}
            {center?.length && (
              <Marker position={center} icon={customIcon}>
                <Marker position={currentLocation} icon={locationIcon} />
                <Popup minWidth={140} closeButton={false} className="popup-map">
                  <MapTooltip>
                    <div className="wrapper">
                      <span className="label">{t('gps_coords')}</span>
                      <span className="value">{`${center[0]}; ${center[1]}`}</span>
                    </div>
                    <div className="wrapper">
                      <span className="label">{t('upload_date')}</span>
                      <span className="value">upload_date</span>
                    </div>
                  </MapTooltip>
                </Popup>
              </Marker>
            )}
          </MapContainer>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner animation="border" variant="dark" />
        </SpinnerWrapper>
      )}
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('update_ap')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.UPDATE_AP_PROPERTY.path, { id: params?.id }),
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          text={t('subplots')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(generatePath(routes.SUBPLOTS.path, { id: params?.id }));
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default PropertyMapScreen;
