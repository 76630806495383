/* eslint-disable no-unused-vars */
import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  EVALUATION_SEND,
  EVALUATION_SEND_SUCCESS,
  EVALUATION_SEND_ERROR,
  EVALUATION_SEND_IMAGES,
  EVALUATION_SEND_IMAGES_SUCCESS,
  EVALUATION_SEND_IMAGES_ERROR,
} from './types';
import { HTTP_STATUS } from '../../constants';
import { getAuthToken } from '../../services/local';

export const sendEvaluation = (data, sendComment) => async (dispatch) => {
  dispatch({ type: EVALUATION_SEND });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const body = {
    evaluations: data?.map((ev) => ({
      evaluationId: ev?.evaluationId,
      commentsTecnico: sendComment,
      autoEvaluation: false,
      answers: ev?.evaluation?.map((e) => ({
        value: e?.value,
        comment: e?.comment,
        indicatorId: e?.indicatorId,
        pointId: e?.pointId,
      })),
    })),
  };

  try {
    const response = await axios.post(Endpoints.SEND_EVALUATION, body, config);
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: EVALUATION_SEND_SUCCESS,
      });
      return Promise.resolve(response?.data?.dataObject?.status?.evaluations);
    }
  } catch (error) {
    dispatch({ type: EVALUATION_SEND_ERROR });
    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const sendEvaluationImages =
  (data, evaluationResponse) => async (dispatch) => {
    dispatch({ type: EVALUATION_SEND_IMAGES });

    const config = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };

    const body = {
      evaluations: evaluationResponse?.map((item) => {
        const evaluation = {
          evaluationId: item?.evaluationId,
          answers: item?.answers.map((answer) => {
            const file = data?.find((f) => f?.pointId === answer?.pointId);
            const imgFiles = file
              ? file?.files?.answers?.filter(
                  (f) => f?.indicatorId === answer?.indicatorId,
                )
              : [];
            return {
              answerId: answer?.answerId,
              base64ImageFiles: imgFiles?.map(
                (file) => file?.base64ImageFiles,
              )[0],
            };
          }),
        };
        return evaluation;
      }),
    };

    try {
      const response = await axios.post(
        Endpoints.SEND_EVALUATION_IMAGES,
        body,
        config,
      );
      const statusCode = response.status;
      if (statusCode === HTTP_STATUS.SUCCESS) {
        dispatch({
          type: EVALUATION_SEND_IMAGES_SUCCESS,
        });
        return Promise.resolve(response?.data?.message);
      }
    } catch (error) {
      dispatch({ type: EVALUATION_SEND_IMAGES_ERROR });
      return Promise.reject(getErrorMessage(error, 'something_wrong'));
    }
  };
