/* eslint-disable no-unused-vars */

import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Container,
  Text,
  ButtonContainer,
  Card,
  SubPlotsTable,
} from './SubPlotsInEvaluationScreen.styles';
import { colors } from '../../constants/colors';
import CustomButton from '../../components/CustomButton/CustomButton';
import PopUp from '../../components/PopUp/PopUp';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import { getDownloadAlert } from '../../services/local';
import ValidatedDownloadAlertForm from '../../components/ValidatedDownloadAlertForm/ValidatedDownloadAlertForm';
import send from '../../assets/icons/send.svg';
import { getSubPlotsInEvaluation } from '../../store/actions/subplots';

const SubPlotsInEvaluationScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subplots = useSelector(
    (state) => state?.subplots?.subPlotsInEvaluation,
  );
  const [evaluationsData, setEvaluationsData] = useState([]);

  useEffect(() => {
    dispatch(getSubPlotsInEvaluation());
  }, [dispatch]);

  useEffect(() => {
    setEvaluationsData(subplots);
  }, [subplots]);

  const handleChangeCheckbox = (index, checked) => {
    const newArr = [...evaluationsData];
    newArr[index].checked = checked;
    setEvaluationsData(newArr);
  };

  const unfinishedEvaluations = useMemo(
    () =>
      !!evaluationsData?.filter(
        (e) => e.complete !== e.available && e.checked,
      )[0]?.id,
    [evaluationsData],
  );

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title="Property 1"
        subtitle={t('sync')}
        download
      />
      {/* TODO PROPERTY NAME */}
      <PopUp open={!getDownloadAlert()}>
        <ValidatedDownloadAlertForm />
      </PopUp>
      <Container>
        <Text>{t('subplots-in-evaluation')}</Text>
        <Card>
          <SubPlotsTable>
            <tbody>
              {evaluationsData?.map((subPlot, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      id={`check-${subPlot?.id}`}
                      name={`check-${subPlot?.id}`}
                      checked={subPlot?.checked}
                      onChange={(e) =>
                        handleChangeCheckbox(index, e?.target?.checked)
                      }
                    />
                  </td>
                  <td>{subPlot?.name}</td>
                  <td>{`${subPlot?.complete}/${subPlot?.available}`}</td>
                </tr>
              ))}
            </tbody>
          </SubPlotsTable>
        </Card>
      </Container>
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={send}
          text={t('send-evaluations')}
          backgroundColor={colors.primaryColor}
          type="submit"
          disabled={unfinishedEvaluations}
          onClick={() => {
            console.log('send'); // TEMP
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default SubPlotsInEvaluationScreen;
