import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 105;
  padding: 35px;
  transition: all 400ms ease-in-out;
  display: flex;
  position: fixed;
  inline-size: 100%;
  overflow-y: scroll;
`;

export const Modal = styled.div`
  position: relative;
  margin: auto;
  background-color: ${colors.white};
  padding: 30px;
  width: 100%;
  max-width: 430px;
  border-radius: 15px;
  box-shadow: ${colors.shadow1} 0px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 106;
`;

export const Close = styled.img`
  height: 10px;
  width: 10px;
  right: 15px;
  top: 15px;
  background-color: transparent;
  position: absolute;
`;
