import React from 'react';
import {
  Container,
  Title,
  Description,
  SubTitle,
  TableHeader,
  TableWrapper,
} from './HelpGuide.styles';

const HelpGuide = ({ analysysData }) => (
  <Container>
    <Title>{analysysData?.header}</Title>
    <Description>{analysysData?.description}</Description>
    <SubTitle>{analysysData?.subtitle}</SubTitle>
    {analysysData?.scaleInfo?.map((level, index) => (
      <div key={index}>
        <TableHeader className={level?.color}>{level?.header}</TableHeader>
        <TableWrapper>{level?.description}</TableWrapper>
      </div>
    ))}
  </Container>
);

export default HelpGuide;
