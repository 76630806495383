import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${breakpoints.desktop};
  padding-bottom: 15px;
  z-index: 101;
`;

export const Button = styled.button`
  border: none;
  background-color: ${colors.primaryColor};
  z-index: 101;
  padding: 18px;
  border-radius: 100%;
  bottom: 0%;
  display: flex;
  align-items: center;
  position: relative;
  margin: auto;

  :hover {
    border: 1px solid ${colors.primaryColor};
  }

  :active {
    border: 1px solid ${colors.white};
  }

  img {
    height: 24px;
    width: 24px;
  }
`;
