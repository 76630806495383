/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Polyline,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import { extractPoints, extractPolyline } from '../../helpers/utils';
import 'leaflet/dist/leaflet.css';
import {
  Page,
  Container,
  ButtonContainer,
  Title,
  Text,
} from './MapScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import Points from '../../data/pontos.kml';
import Subplot from '../../data/subparcela.kml';
import addIcon from '../../assets/icons/plus.svg';
import evaluationIcon from '../../assets/icons/evaluation.svg';
import { getCurrentLocation } from '../../services/geolocation';
import PopUp from '../../components/PopUp/PopUp';
import accessPointIcon from '../../assets/icons/access-point.svg';

const MapScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [nearPlot, setNearPlot] = useState(false);
  const [plot, setPlot] = useState('');
  const [center, setCenter] = useState([38.5360285073504, -8.00468334252444]);
  const params = useParams();

  // States for Map ( Map | Markers | Polygons)
  const [markers, setMarkers] = useState(null);
  const [polyline, setPolyline] = useState(null);

  // Markers: https://github.com/pointhi/leaflet-color-markers
  const customIcon = new Icon({
    iconUrl: accessPointIcon,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [38, 48],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    (async () => {
      const interval = setInterval(async () => {
        const location = await getCurrentLocation();

        setCurrentPosition(location);
        // setCurrentPosition({ lat: 38.547347, lng: -8.142377 });
      }, 10000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    })();
  }, []);

  useEffect(() => {
    if (currentPosition && markers) {
      // {"lat": 38.547347,"lng": -8.142377},"name": "PA1"

      markers?.forEach((marker) => {
        if (
          L.latLng(marker?.lat, marker?.lng).distanceTo(
            L.latLng(currentPosition?.lat, currentPosition?.lng),
          ) < 5
        ) {
          setNearPlot(true);
          setPlot(marker.name);
        }
      });
    }
  }, [currentPosition, markers]);

  // Parses Kml local documents into Markers to be rendered offline instead of using google API
  const parsePoints = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polygons from de Document
    fileReader.onload = async (e) => {
      const result = await extractPoints(e.target.result);
      setMarkers(result?.markers);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Parses Kml local documents into Polyline to be rendered offline instead of using google API
  const parseSubplot = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polyline from de Document
    fileReader.onload = async (e) => {
      const result = await extractPolyline(e.target.result);
      setPolyline(result?.polyline);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Runs Only once at the screen rendering, fetchs the local document and parses it
  useEffect(() => {
    fetch(Points)
      .then((r) => r.text())
      .then((text) => parsePoints(text));
    fetch(Subplot)
      .then((r) => r.text())
      .then((text) => parseSubplot(text));
  }, []);

  const backHandler = () => {
    navigate(-1);
  };

  const markerHandler = (num) => {
    // change Route to the point evaluation
    navigate(
      generatePath(routes.ADDAP_SUBPLOT.path, {
        id: params?.id,
        plot_id: num,
      }),
    );
  };

  // TODO: Check if evaluation is completed with dummy data (completed 1 and 12)
  const isEvaluated = useMemo(
    () => ['2', '12'].includes(params?.id),
    [params?.id],
  );

  return (
    <Page>
      <PopUp open={nearPlot}>
        <Title>{t('alert')}</Title>
        <Text>
          {t('near_point')} {plot}
        </Text>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
            marginTop: 40,
          }}
          icon={evaluationIcon}
          text={t('evaluate')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            alert('TODO IMPLEMENT EVALUATION');
            setNearPlot(false);
            // navigate(routes.Evaluation.path);
          }}
        />
      </PopUp>
      {/* TODO PROPERTY NAME */}
      <TopBar back={backHandler} title="Parcela 1" subtitle={t('evaluation')} />

      <Container>
        <MapContainer center={center} zoom={16} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {polyline && <Polyline positions={polyline} />}
          {markers?.map((marker, index) => (
            <Marker
              key={index}
              eventHandlers={{
                click: () => {
                  if (isEvaluated) return;
                  markerHandler(marker?.id || 1);
                },
              }}
              position={[marker?.lat, marker?.lng]}
              icon={customIcon}
            >
              <Tooltip direction="top" opacity={1} permanent>
                {marker?.name}
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
      </Container>
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          disabled={isEvaluated}
          icon={addIcon}
          text={t('add_pe')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.ADDPE.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          disabled={isEvaluated}
          icon={addIcon}
          text={t('update_ap')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.ADDAP_SUBPLOT.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
      </ButtonContainer>

      <HomeButton routes={routes} />
    </Page>
  );
};

export default MapScreen;
