import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    color: ${colors.primaryColor};
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .body {
    color: ${colors.text};
    font-size: 18px;
    margin-bottom: 30px;
  }

  img {
    border-radius: 10px;
  }
`;
