import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 35px;
  height: auto;
`;

export const Card = styled.div`
  background-color: ${colors.white};
  max-width: ${breakpoints.desktop};
  height: calc(100vh - 110px);
  padding: 30px;
  border-radius: 15px 15px 0px 0px;
  margin-top: -40px;
  z-index: 99;
  box-shadow: 0 0 10px ${colors.shadow2};
  width: 100vw;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -60px;
    height: calc(100vh - 140px);
  }
`;

export const Text = styled.div`
  font-size: 20px;
  color: ${colors.text};
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 20px;
`;
