import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  max-width: 430px;
  margin: auto;
  margin-top: -40px;
  position: relative;
  z-index: 100;
  @media (max-width: ${breakpoints.mobile}) {
    max-width: 340px;
  }
`;

export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  height: 135px;
  background-color: ${colors.white};
  filter: drop-shadow(0 0 10px ${colors?.shadow1});

  .title {
    font-size: 18px;
    color: ${colors.text};
  }

  .value {
    font-size: 30px;
    font-weight: 600;
    color: ${colors.primaryColor};
  }
`;
