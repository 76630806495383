import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper, Label } from './LevelD32.styles';
import ToggleButton from '../../ToggleButton/ToggleButton';
import { D32_COVERAGE_OPTIONS, FORM_D32_PARAMETERS } from '../../../constants';
import { levelSuggestionD32 } from '../../../helpers/utils';
import PercentageRange from '../../PercentageRange/PercentageRange';
import CustomInputFull from '../../CustomInputFull/CustomInputFull';

const LevelD32 = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();

  const levelD32Ref = useRef({
    dominant: false,
    bushes: '',
    coverageLevel: 0,
  });

  const handleKeyDownEvent = useCallback((evt) => {
    if (['e', 'E', '+', '-'].includes(evt?.key)) evt.preventDefault();
  }, []);

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D32_PARAMETERS.DOMINANT:
          levelD32Ref.current.dominant = val;
          break;
        case FORM_D32_PARAMETERS.BUSHES:
          levelD32Ref.current.bushes = val;
          break;
        case FORM_D32_PARAMETERS.COVERAGE_LEVEL:
          levelD32Ref.current.coverageLevel = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD32(
          levelD32Ref?.current?.dominant,
          levelD32Ref?.current?.bushes,
          levelD32Ref?.current?.coverageLevel,
        ),
      );
    },
    [levelD32Ref, setLevel],
  );

  return (
    <FormWrapper>
      <div className="formRow">
        <span className="label">{t('form-d32.dominant')}</span>
        <ToggleButton
          id={`dominant-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD32Ref?.current?.dominant}
          onClick={(val) => handleDataChange(val, FORM_D32_PARAMETERS.DOMINANT)}
        />
      </div>
      <div className="formRow">
        <Label htmlFor={`bushes-${indicatorId}`}>{t('form-d32.bushes')}</Label>
        <CustomInputFull
          id={`bushes-${indicatorId}`}
          name={`bushes-${indicatorId}`}
          type="number"
          style={{ maxWidth: '75px' }}
          onKeyDown={(evt) => handleKeyDownEvent(evt)}
          onChange={(e) =>
            handleDataChange(e?.target?.value, FORM_D32_PARAMETERS.BUSHES)
          }
          value={levelD32Ref?.current?.bushes}
        />
      </div>
      <PercentageRange
        title={t('form-d32.title-bushes')}
        percentage={levelD32Ref?.current?.coverageLevel}
        options={D32_COVERAGE_OPTIONS}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D32_PARAMETERS.COVERAGE_LEVEL)
        }
      />
      <hr />
    </FormWrapper>
  );
};

export default LevelD32;
