import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import downloadIcon from '../../assets/icons/TopBar/download.svg';
import xIcon from '../../assets/icons/TopBar/x.svg';
import checkIcon from '../../assets/icons/TopBar/check.svg';
import {
  Container,
  Title,
  Header,
  Content,
  InputWrapper,
  Buttons,
} from './ValidatedDownloadAlertForm.styles';
import { colors } from '../../constants/colors';
// import { recoverPassword } from '../../store/actions/auth';
// import CustomInput from '../CustomInput/CustomInput';
import CustomButton from '../CustomButton/CustomButton';
import { setDownloadAlert } from '../../services/local';

const ValidatedDownloadAlertForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.auth.loading);

  const downloadClickHandler = useCallback(
    (values) => {
      console.log(values, dispatch, t);

      if (values.check === true) {
        setDownloadAlert();
      }
      navigate(0);

      // dispatch(downloadProperty(values.check))
      //   .then(() => {
      //     alert(t('downloaded'));
      //   })
      //   .catch((error) => {
      //     // eslint-disable-next-line no-undef
      //     alert(error);
      //   });
    },
    [dispatch, t, navigate],
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      downloadClickHandler(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>{t('alert')}</Title>
      <Header>{t('alert_header')}</Header>
      <Content>{t('alert_content')}</Content>
      <Buttons>
        <div className="button">
          <img className="storage" src={xIcon} alt="x" />
          <img src={downloadIcon} alt={t('download')} />
        </div>
        <div className="button">
          <img className="storage" src={checkIcon} alt="x" />
          <img src={downloadIcon} alt={t('download')} />
        </div>
      </Buttons>
      <InputWrapper>
        <input
          type="checkbox"
          id="check"
          name="check"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.check}
        />
        <span>{t('check_text')}</span>
      </InputWrapper>
      <CustomButton
        style={{
          marginTop: 0,
          marginBottom: 0,
          width: '100%',
          borderRadius: '5px',
        }}
        text={t('confirm_and_download')}
        backgroundColor={loading ? colors.grey : colors.primaryColor}
        loading={loading}
        disabled={loading}
        type="submit"
      />
    </Container>
  );
};

export default ValidatedDownloadAlertForm;
