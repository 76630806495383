import React from 'react';
import { ANSWER_TYPE } from '../../../constants';
import LevelD32 from '../../../components/DecisionLevel/LevelD32/LevelD32';
import LevelRate from '../../../components/LevelRate/LevelRate';
import LevelD33 from '../../../components/DecisionLevel/LevelD33/LevelD33';
import LevelD34 from '../../../components/DecisionLevel/LevelD34/LevelD34';
import LevelD35 from '../../../components/DecisionLevel/LevelD35/LevelD35';
import LevelD36A from '../../../components/DecisionLevel/LevelD36A/LevelD36A';
import LevelD36B from '../../../components/DecisionLevel/LevelD36B/LevelD36B';

const LevelSelector = ({
  indicator,
  handleChangeFormData,
  setOpenHelpGuide,
  getSelectedValue,
}) => (
  <>
    {indicator?.answerType === ANSWER_TYPE?.MA && (
      <LevelD32
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    {indicator?.answerType === ANSWER_TYPE?.AR && (
      <LevelD33
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    {indicator?.answerType === ANSWER_TYPE?.CT && (
      <LevelD34
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    {indicator?.answerType === ANSWER_TYPE?.CP && (
      <LevelD35
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    {indicator?.answerType === ANSWER_TYPE?.GT && (
      <LevelD36A
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    {indicator?.answerType === ANSWER_TYPE?.GP && (
      <LevelD36B
        setLevel={(e) => handleChangeFormData(indicator?.id, e)}
        indicatorId={indicator?.id}
      />
    )}
    <LevelRate
      options={indicator?.answerOptions}
      setOpenHelpGuide={setOpenHelpGuide}
      selectedLevel={getSelectedValue(indicator?.id)}
      id={indicator?.id}
      name={indicator?.id}
      onClick={(e) => handleChangeFormData(indicator?.id, e)}
    />
  </>
);

export default LevelSelector;
