import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100%;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
`;

export const Card = styled.div`
  background-color: ${colors.white};
  height: auto;
  padding: 30px;
  padding-bottom: 160px;
  border-radius: 15px 15px 0 0;
  margin-top: -30px;
  z-index: 99;
  box-shadow: 0 0 10px ${colors.shadow2};
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

  .label {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.color};
  }
`;

export const Info = styled.button`
  position: absolute;
  margin-top: 30px;
  right: 18px;
  z-index: 1;
  border-radius: 100%;
  border: none;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 5px ${colors.shadow2};
  background-color: ${colors.white};
  img {
    display: flex;
    margin: auto;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${breakpoints.desktop};
  padding: 15px 35px;
  z-index: 99;
  bottom: 0;
  gap: 40px;
  display: flex;
  flex-direction: row;
  background: ${colors.white};
  filter: ${(props) =>
    props.dropshadow ? `drop-shadow(0 0 10px ${colors.shadow1})` : ''};
`;

export const TabsContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${breakpoints.desktop};
  z-index: 99;
  bottom: 80px;
  gap: 5px;
  height: 60px;
  line-height: 60px;
  display: flex;
  flex-direction: row;
  background: ${colors.white};
  filter: drop-shadow(0 0 10px ${colors.shadow1});
`;

export const BottomTab = styled.div`
  margin-top: auto;
  width: 100%;
  text-align: center;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;

  &.solo {
    background: ${colors.coyoteBrown};
  }

  &.regeneracao {
    background: ${colors.moonstoneBlue};
  }

  &.pastagem {
    background: ${colors.floral};
  }

  :not(.highlight) {
    opacity: 0.5;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) => (props.alignment === true ? 'column' : 'row')};
  gap: 5px;
  padding: 15px 0;

  button,
  input {
    border: none;
    background-color: unset;
    padding: 0;
  }

  .photo-input {
    display: none;
  }
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const Photo = styled.div`
  width: 170px;
  position: relative;

  .uploaded-img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }

  .remove-img {
    border: 0;
    background: unset;
    width: 10px;
    position: absolute;
    right: 15px;
    top: 3px;
    z-index: 1;
    img {
      width: inherit;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  margin-right: auto;
  margin-top: 50%;
  margin-left: auto;
`;
