import {
  EVALUATION_SEND,
  EVALUATION_SEND_SUCCESS,
  EVALUATION_SEND_ERROR,
  EVALUATION_SEND_IMAGES,
  EVALUATION_SEND_IMAGES_SUCCESS,
  EVALUATION_SEND_IMAGES_ERROR,
} from '../actions/types';

const initialState = {
  loading: false,
};

const evaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVALUATION_SEND:
      return {
        ...state,
        loading: true,
      };
    case EVALUATION_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EVALUATION_SEND_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EVALUATION_SEND_IMAGES:
      return {
        ...state,
        loading: true,
      };
    case EVALUATION_SEND_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EVALUATION_SEND_IMAGES_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default evaluationReducer;
