import { createGlobalStyle } from 'styled-components';
import OmnibusType from './assets/fonts/Archivo-VariableFont_wdth,wght.ttf';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { colors } from './constants/colors';
import { breakpoints } from './constants/breakpoints';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Omnibus-Type';
    src: url(${OmnibusType}) format('truetype');
  }

html {
  height: 100%;
}

  #root {
    height: 100%;
  }

  body {
    font-family: 'Omnibus-Type';
    margin: 0;
    overscroll-behavior-y: contain;
    max-width: ${breakpoints.l_desktop};
    height: 100%;
    margin: 0px auto;
    background-color: ${colors.background};
    word-wrap: break-word;

    b {
      font-family: 'NotoSans-Bold';
    }

    button {
      -webkit-appearance: none;
      -webkit-border-radius: 0;
    }
    input[type='checkbox'] {
    accent-color: ${colors.primaryColor};
}
  }
`;

export default GlobalStyles;
