import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import {
  Container,
  Label,
  Error,
  Wrapper,
  InputWrapper,
} from './ValidatedUpdatePointForm.styles';
import { colors } from '../../constants/colors';
import CustomInputFull from '../CustomInputFull/CustomInputFull';
import CustomButton from '../CustomButton/CustomButton';
import { getCurrentLocation } from '../../services/geolocation';
import saveIcon from '../../assets/icons/save.svg';
import CustomSelect from '../CustomSelect/CustomSelect';

const ValidatedUpdatePointForm = ({ readOnly, selectedPosition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPosition, setCurrentPosition] = useState(null);

  const handleUpdatePoint = useCallback(
    (values) => {
      console.log(values, dispatch);
    },
    [dispatch],
  );

  useEffect(() => {
    if (selectedPosition) return setCurrentPosition(selectedPosition);
    getCurrentLocation().then((value) => setCurrentPosition(value));
  }, [selectedPosition]);

  const formik = useFormik({
    initialValues: {
      type: '',
      identification: '',
      latitude: currentPosition?.lat,
      longitude: currentPosition?.lng,
      area: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required(t('required')),
      identification: Yup.string().required(t('required')),
      latitude: Yup.number().required(t('required')),
      longitude: Yup.number().required(t('required')),
      area: Yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      handleUpdatePoint(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {!currentPosition ? (
        <Wrapper>
          <Spinner animation="border" variant="dark" />
          <Label>{t('getting_coordinates')}</Label>
        </Wrapper>
      ) : (
        <Wrapper>
          <InputWrapper>
            <Label htmlFor="type">{t('type')}</Label>
            <CustomSelect
              id="type"
              name="type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.type}
            />
            {formik.touched.type && formik.errors.type ? (
              <Error>{formik.errors.type}</Error>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="identification">{t('identification')}</Label>
            <CustomInputFull
              id="identification"
              name="identification"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.identification}
              readOnly={readOnly}
            />
            {formik.touched.identification && formik.errors.identification ? (
              <Error>{formik.errors.identification}</Error>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="latitude">{t('latitude')}</Label>
            <CustomInputFull
              id="latitude"
              name="latitude"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.latitude}
              readOnly={readOnly}
            />
            {formik.touched.latitude && formik.errors.latitude ? (
              <Error>{formik.errors.latitude}</Error>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="longitude">{t('longitude')}</Label>
            <CustomInputFull
              id="longitude"
              name="longitude"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.longitude}
              readOnly={readOnly}
            />
            {formik.touched.longitude && formik.errors.longitude ? (
              <Error>{formik.errors.longitude}</Error>
            ) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="area">{t('area')}</Label>
            <CustomInputFull
              id="area"
              name="area"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.area}
              readOnly={readOnly}
            />
            {formik.touched.area && formik.errors.area ? (
              <Error>{formik.errors.area}</Error>
            ) : null}
          </InputWrapper>
        </Wrapper>
      )}

      <CustomButton
        style={{
          marginTop: 'auto',
          width: '100%',
          borderRadius: '5px',
          marginBottom: 90,
        }}
        icon={saveIcon}
        text={t('save')}
        backgroundColor={colors.primaryColor}
        type="submit"
      />
    </Container>
  );
};

export default ValidatedUpdatePointForm;
