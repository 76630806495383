import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Container,
  Properties,
  ButtonContainer,
  PopupButtonContainer,
  SendWrapper,
} from './SubPlotScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import { getProperty } from '../../store/actions/properties';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import SmallMap from '../../components/SmallMap/SmallMap';
import send from '../../assets/icons/send.svg';
import evaluation from '../../assets/icons/evaluation.svg';
import Points from '../../data/pontos.kml';
import Subplot from '../../data/subparcela.kml';
import { getCurrentLocation } from '../../services/geolocation';
import {
  sendEvaluation,
  sendEvaluationImages,
} from '../../store/actions/evaluation';
import {
  getEvaluationData,
  getEvaluationFilesData,
} from '../../services/local';
import PopUp from '../../components/PopUp/PopUp';
import CustomTextarea from '../../components/CustomTextarea/CustomTextarea';

const SubPlotScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const evaluationId = location?.state?.evaluationId;
  const [currentLocation, setCurrentLocation] = useState(null);
  const [canSendEvaluation, setCanSendEvaluation] = useState(false);
  const [openSubmitAlert, setOpenSubmitAlert] = useState(false);
  const [missingEvaluations, setMissingEvaluations] = useState(false);
  const [sendComment, setSendComment] = useState('');
  const savedProperties = useSelector((state) => state.savedData.properties);
  const onlineProperty = useSelector((state) => state.properties.property);
  const property =
    onlineProperty || savedProperties.find((item) => item.id === params?.id);
  const selectedSubplot = property?.subplots?.find(
    (i) => i.id === params?.plot_id,
  );

  const storedEvaluationData = getEvaluationData();
  const storedEvaluationFiles = getEvaluationFilesData();

  const currentSubplotEvaluations = useMemo(
    () => storedEvaluationData?.filter((e) => evaluationId === e?.evaluationId),
    [storedEvaluationData, evaluationId],
  );

  const currentSubplotFiles = useMemo(
    () =>
      storedEvaluationFiles?.filter((e) => evaluationId === e?.evaluationId),
    [storedEvaluationFiles, evaluationId],
  );

  useEffect(() => {
    dispatch(getProperty(params?.id));
  }, [dispatch, params]);

  useEffect(() => {
    if (!currentLocation) {
      getCurrentLocation().then((value) =>
        setCurrentLocation({ lat: value?.lat, lng: value?.lng }),
      );
    }
  }, [currentLocation]);

  useEffect(() => {
    if (
      currentSubplotEvaluations &&
      selectedSubplot?.numberSamplingPoints ===
        currentSubplotEvaluations?.length
    ) {
      setCanSendEvaluation(true);
    } else if (
      currentSubplotEvaluations &&
      currentSubplotEvaluations?.length > 0
    ) {
      setMissingEvaluations(true);
    } else {
      setCanSendEvaluation(false);
      setMissingEvaluations(false);
    }
  }, [selectedSubplot, currentSubplotEvaluations, evaluationId]);

  const closeSubmitAlert = () => {
    setOpenSubmitAlert(false);
  };

  const sendEvaluationData = useCallback(() => {
    dispatch(sendEvaluation(currentSubplotEvaluations, sendComment)).then(
      (res) => {
        if (currentSubplotFiles)
          dispatch(sendEvaluationImages(currentSubplotFiles, res));

        closeSubmitAlert();
      },
    );
  }, [dispatch, currentSubplotFiles, currentSubplotEvaluations, sendComment]);

  const handleEvaluate = useCallback(() => {
    if (canSendEvaluation) {
      sendEvaluationData();
    } else if (missingEvaluations) {
      setOpenSubmitAlert(true);
    }
  }, [canSendEvaluation, missingEvaluations, sendEvaluationData]);

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar
        title={selectedSubplot?.identification}
        subtitle={property?.name}
        back={backHandler}
      />
      <PopUp open={openSubmitAlert} close={closeSubmitAlert}>
        <SendWrapper>
          <p className="header">{t('send')}</p>
          <p className="body">{t('evaluation_missing_points')}</p>
          <p className="comment">{`${t('comment')}`}</p>
          <CustomTextarea
            onChange={(e) => setSendComment(e?.target?.value)}
            rows={4}
          />
        </SendWrapper>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={send}
            text={t('send')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              sendEvaluationData();
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      <Container>
        <Properties>
          <p className="item">
            <span className="label">{t('sampling_points')}:</span>
            <span>{selectedSubplot?.numberSamplingPoints}</span>
          </p>
          <p className="item">
            <span className="label">{t('element_points')}:</span>
            <span>{selectedSubplot?.numberSingularElmentPoints}</span>
          </p>
          <p className="item">
            <span className="label">{t('route')}:</span>
            <span>{selectedSubplot?.route}</span>
          </p>
          <p className="item">
            <span className="label">{t('area')}:</span>
            <span>{`${selectedSubplot?.area} km`}</span>
          </p>
        </Properties>
        {currentLocation && (
          <SmallMap
            center={currentLocation}
            points={Points}
            subplot={Subplot}
          />
        )}
      </Container>
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={evaluation}
          text={t('evaluate')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.SUBPLOT_MAP.path, {
                id: params?.id,
                plot_id: selectedSubplot?.id,
              }),
              { state: { evaluationId } },
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={send}
          disabled={!currentSubplotEvaluations?.length}
          text={t('send')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            handleEvaluate();
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default SubPlotScreen;
