import React from 'react';
import { Container, TableHeader, TableWrapper } from './OptionHelpGuide.styles';

const OptionHelpGuide = ({ options }) => (
  <Container>
    <TableHeader className={options?.color}>{options?.header}</TableHeader>
    <TableWrapper>{options?.description}</TableWrapper>
  </Container>
);

export default OptionHelpGuide;
