import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100%;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 100%;
`;

export const Card = styled.div`
  background-color: ${colors.white};
  height: auto;
  padding: 10px 30px;
  border-radius: 15px 15px 0 0;
  z-index: 99;
  box-shadow: 0 0 10px ${colors.shadow2};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  height: 100%;
`;

export const SubPlotsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 18px;
  td:last-child {
    color: ${colors.primaryColor};
    font-weight: 600;
    font-size: 16px;
    text-align: right;
  }
  tr {
    border-bottom: 1px solid ${colors.grey};
    text-align: left;
    padding: 20px 0;
    td {
      padding: 20px 0;
      color: ${colors.text};
      button {
        all: unset;
        cursor: pointer;
      }
    }
  }
`;

export const Text = styled.div`
  font-size: 20px;
  color: ${colors.text};
  font-weight: 900;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${breakpoints.desktop};
  padding: 35px;
  bottom: 70px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
