import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import authReducer from './reducers/auth';
import propertiesReducer from './reducers/properties';
import subplotsReducer from './reducers/subplots';
import savedDataReducer from './reducers/savedData';
import helpersReducer from './reducers/helpers';
import indicatorsReducer from './reducers/indicators';
import evaluationReducer from './reducers/evaluation';
import statsReducer from './reducers/stats';

export const rootReducer = combineReducers({
  auth: authReducer,
  properties: propertiesReducer,
  subplots: subplotsReducer,
  savedData: savedDataReducer,
  helpers: helpersReducer,
  indicators: indicatorsReducer,
  evaluation: evaluationReducer,
  stats: statsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'savedData'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
const persistor = persistStore(store);
export { store, persistor };
