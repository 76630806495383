import React from 'react';
import { PercentageWrapper } from './PercentageRange.styles';

const PercentageRange = ({ title, percentage, setPercentage, options }) => (
  <PercentageWrapper>
    <span className="label">{title}</span>
    <div className="percentageOptions">
      {options?.map((opt, index) => (
        <span
          className={`${percentage === opt?.value ? 'highlight' : ''}`}
          key={index}
        >
          <button
            type="button"
            onClick={() => setPercentage(opt?.value)}
            className="no-styles"
          >
            {opt?.label}
          </button>
        </span>
      ))}
    </div>
  </PercentageWrapper>
);

export default PercentageRange;
