/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import {
  Page,
  Container,
  ButtonContainer,
  Card,
  Info,
  TabsContainer,
  BottomTab,
  ActionsWrapper,
  SpinnerWrapper,
  Photo,
  PhotosContainer,
} from './EvaluateSubplotScreen.styles';
import { colors } from '../../constants/colors';
import CustomButton from '../../components/CustomButton/CustomButton';
import PopUp from '../../components/PopUp/PopUp';
import TopBar from '../../components/TopBar/TopBar';
import AlertInfo from '../../components/AlertInfo/AlertInfo';
import infoIcon from '../../assets/icons/TopBar/info.svg';
import commentIcon from '../../assets/icons/comment.svg';
import cameraIcon from '../../assets/icons/camera.svg';
import closeIcon from '../../assets/icons/close-white.svg';
import { createPhotoId, getBase64, normalizeStr } from '../../helpers/utils';
import HelpGuide from '../../components/HelpGuide/HelpGuide';
import OptionHelpGuide from '../../components/OptionHelpGuide/OptionHelpGuide';
import { getSubPlotsAnalysysData } from '../../store/actions/subplots';
import { getGeneralHelp } from '../../store/actions/helpers';
import { getPointTypeIndicators } from '../../store/actions/indicators';
import { INDICATOR_TABS } from '../../constants';
import CustomAccordion from '../../components/CustomAccordion/CustomAccordion';
import CustomTextarea from '../../components/CustomTextarea/CustomTextarea';
import {
  getEvaluationData,
  getEvaluationFilesData,
  saveEvaluationData,
  saveEvaluationFilesData,
} from '../../services/local';
import LevelSelector from './LevelSelector/LevelSelector';

const EvaluateSubplotScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pointId = location?.state?.pointId;
  const pointName = location?.state?.pointName;
  const evaluationId = location?.state?.evaluationId;
  const pointTypeId = location?.state?.pointTypeId;
  const [openInfo, setOpenInfo] = useState(false);
  const [openFormInfo, setOpenFormInfo] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [contentColor, setContentColor] = useState(null);
  const [openHelpGuide, setOpenHelpGuide] = useState(null);
  const [formData, setFormData] = useState(null);
  const [selectedImages, setSelectedImages] = useState({
    evaluationId,
    answers: [],
  });
  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const storedEvaluationData = getEvaluationData();
  const storedEvaluationFiles = getEvaluationFilesData();

  const analysysData = useSelector((state) => state?.subplots?.analysysData);
  const pointType = useSelector(
    (state) => state?.indicators?.pointTypeIndicators,
  );
  const generalHelpData = useSelector(
    (state) => state?.helpers?.generalHelp[0],
  );

  const showTabContainer = pointType?.length > 1;

  useEffect(() => {
    dispatch(getSubPlotsAnalysysData());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTab(pointType[0]);
  }, [dispatch, pointType]);

  useEffect(() => {
    if (pointTypeId) dispatch(getPointTypeIndicators(pointTypeId));
  }, [dispatch, pointTypeId]);

  useEffect(() => {
    if (pointType)
      setFormData(
        pointType
          ?.map((category) =>
            category?.indicators.map((indicator) => ({
              indicatorId: indicator?.id,
              pointId,
              value: null,
              comment: '',
              toggleComment: false,
            })),
          )
          .flat(1),
      );
  }, [pointType, pointId]);

  useEffect(() => {
    dispatch(getGeneralHelp());
  }, [dispatch]);

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const closeInfo = () => {
    setOpenInfo(false);
  };

  const closeFormInfo = () => {
    setOpenFormInfo(false);
  };

  const closeHelpGuide = () => {
    setOpenHelpGuide(null);
  };

  const handleChangeFormData = useCallback(
    (id, value) => {
      const updatedList = formData?.map((item) => {
        if (item?.indicatorId === id) {
          return { ...item, value };
        }
        return item;
      });
      setFormData(updatedList);
    },
    [formData],
  );

  const handleChangeComment = useCallback(
    (id, value) => {
      const updatedList = formData?.map((item) => {
        if (item?.indicatorId === id) {
          return { ...item, comment: value };
        }
        return item;
      });
      setFormData(updatedList);
    },
    [formData],
  );

  const handleEvaluatePoint = useCallback(() => {
    const evaluationData = {
      evaluationId,
      pointId,
      evaluation: formData,
    };
    const evaluationFiles = {
      evaluationId,
      pointId,
      files: selectedImages,
    };

    if (evaluationData)
      saveEvaluationData(
        JSON.stringify([...(storedEvaluationData ?? []), evaluationData]),
      );

    if (evaluationFiles)
      saveEvaluationFilesData(
        JSON.stringify([...(storedEvaluationFiles ?? []), evaluationFiles]),
      );

    backHandler();
  }, [
    formData,
    storedEvaluationData,
    storedEvaluationFiles,
    evaluationId,
    selectedImages,
    pointId,
    backHandler,
  ]);

  const handleToggleComment = useCallback(
    (id) => {
      const updatedList = formData?.map((item) => {
        if (item?.indicatorId === id) {
          return { ...item, toggleComment: !item?.toggleComment };
        }
        return item;
      });
      setFormData(updatedList);
    },
    [formData],
  );

  const handleSelectImages = useCallback(
    (indicatorId, files) => {
      const uploadedImgs = [];
      Array.from(files)?.forEach((file) =>
        getBase64(file).then((base64) => {
          uploadedImgs?.push({
            id: createPhotoId(),
            indicatorId,
            fileName: file?.name,
            b64FileContent: base64,
          });
        }),
      );
      setTimeout(() => {
        setSelectedImages((prevState) => {
          const newState = {
            evaluationId,
            answers: [],
          };

          uploadedImgs.forEach((obj) => {
            const existingAnswer = prevState?.answers?.find(
              (answer) => answer.indicatorId === obj.indicatorId,
            );
            if (existingAnswer) {
              newState.answers = prevState.answers.map((ans) => {
                if (ans?.indicatorId === indicatorId) {
                  ans.base64ImageFiles.push(obj);
                }
                return ans;
              });
            } else {
              newState.answers = [
                ...prevState.answers,
                {
                  base64ImageFiles: [obj],
                  indicatorId: obj.indicatorId,
                },
              ];
            }
          });

          return newState;
        });
      }, 100);
    },
    [evaluationId],
  );

  const removePhoto = useCallback(
    (photoId, indicatorId) => {
      const newArr = selectedImages?.answers.map((ans) => {
        if (ans?.indicatorId === indicatorId) {
          ans.base64ImageFiles = ans.base64ImageFiles?.filter(
            (file) => file?.id !== photoId,
          );
        }
        return ans;
      });

      setSelectedImages((prevFiles) => ({
        ...prevFiles,
        answers: newArr,
      }));
    },
    [selectedImages],
  );

  const handleExpandAccordion = useCallback(
    (id) => {
      if (!expandedAccordion.includes(id)) {
        setExpandedAccordion([...expandedAccordion, id]);
      } else {
        const newArr = expandedAccordion?.filter(
          (accordion) => accordion !== id,
        );
        setExpandedAccordion(newArr);
      }
    },
    [expandedAccordion],
  );

  const getSelectedValue = (id) =>
    formData?.filter((item) => item?.indicatorId === id)[0]?.value;

  const getSelectedComment = (id) =>
    formData?.filter((item) => item?.indicatorId === id)[0]?.comment;

  const getCommentSectionState = (id) =>
    formData?.filter((item) => item?.indicatorId === id)[0]?.toggleComment;

  useEffect(() => {
    const pointDescription = normalizeStr(selectedTab?.description);
    switch (pointDescription) {
      case INDICATOR_TABS.SOLO:
        return setContentColor(colors?.coyoteBrown);
      case INDICATOR_TABS.REGENERACAO:
        return setContentColor(colors?.moonstoneBlue);
      case INDICATOR_TABS.PASTAGEM:
        return setContentColor(colors?.floral);
      default:
        return setContentColor(colors?.text);
    }
  }, [selectedTab]);

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={pointName}
        subtitle="Subparcela 1"
        info={() => setOpenInfo(true)}
      />
      <PopUp open={openInfo} close={closeInfo}>
        <AlertInfo
          description={generalHelpData?.description}
          image={generalHelpData?.imageUrl}
        />
      </PopUp>
      <PopUp open={openHelpGuide} close={closeHelpGuide}>
        <OptionHelpGuide options={openHelpGuide} />
      </PopUp>
      <PopUp open={openFormInfo} close={closeFormInfo}>
        <HelpGuide analysysData={analysysData} />
      </PopUp>
      <Container>
        {selectedTab ? (
          <Card color={contentColor}>
            <span className="label">{selectedTab?.description}</span>
            <Info onClick={() => setOpenFormInfo(true)}>
              <img src={infoIcon} alt={t('info')} />
            </Info>
            {selectedTab?.indicators?.map((indicator) => (
              <CustomAccordion
                headerText={indicator?.description}
                contentColor={contentColor}
                id={indicator?.id}
                key={indicator?.id}
                onChange={handleExpandAccordion}
                expanded={expandedAccordion}
              >
                <LevelSelector
                  indicator={indicator}
                  handleChangeFormData={handleChangeFormData}
                  setOpenHelpGuide={setOpenHelpGuide}
                  getSelectedValue={getSelectedValue}
                />
                <hr />
                <ActionsWrapper
                  alignment={getCommentSectionState(indicator?.id)}
                >
                  <button
                    type="button"
                    onClick={() => handleToggleComment(indicator?.id)}
                  >
                    <img src={commentIcon} alt="comment-icon" />
                  </button>
                  {getCommentSectionState(indicator?.id) && (
                    <CustomTextarea
                      value={getSelectedComment(indicator?.id)}
                      onChange={(e) =>
                        handleChangeComment(indicator?.id, e?.target?.value)
                      }
                      close={() => handleToggleComment(indicator?.id)}
                    />
                  )}
                  <div className="image-upload">
                    <label htmlFor={`img-upload-${indicator?.id}`}>
                      <img src={cameraIcon} alt="camera-icon" />
                      <input
                        id={`img-upload-${indicator?.id}`}
                        type="file"
                        name={`img-upload-${indicator?.id}`}
                        className="photo-input"
                        multiple
                        onChange={(e) =>
                          handleSelectImages(indicator?.id, e.target.files)
                        }
                      />
                    </label>
                  </div>
                </ActionsWrapper>
                {selectedImages?.answers?.length ? (
                  <PhotosContainer>
                    {selectedImages?.answers
                      ?.filter((pic) => pic?.indicatorId === indicator?.id)[0]
                      ?.base64ImageFiles?.filter(
                        (file) => file?.indicatorId === indicator?.id,
                      )
                      .map((photo, index) => (
                        <Photo key={index}>
                          <img
                            className="uploaded-img"
                            alt="img"
                            src={`data:image/png;base64,${photo?.b64FileContent}`}
                          />
                          <button
                            type="button"
                            className="remove-img"
                            onClick={() =>
                              removePhoto(photo?.id, indicator?.id)
                            }
                          >
                            <img src={closeIcon} alt="close" />
                          </button>
                        </Photo>
                      ))}
                  </PhotosContainer>
                ) : null}
              </CustomAccordion>
            ))}
          </Card>
        ) : (
          <SpinnerWrapper>
            <Spinner animation="border" variant="dark" />
          </SpinnerWrapper>
        )}
      </Container>
      {showTabContainer && (
        <TabsContainer>
          {pointType?.map((tab, index) => (
            <BottomTab
              key={index}
              className={`${
                tab?.description === selectedTab?.description ? 'highlight' : ''
              } ${normalizeStr(tab?.description)}`}
              onClick={() => setSelectedTab(tab)}
            >
              <span>{tab?.description}</span>
            </BottomTab>
          ))}
        </TabsContainer>
      )}
      <ButtonContainer dropshadow={!showTabContainer}>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
            border: `1px solid ${colors.primaryColor}`,
          }}
          text={t('cancel')}
          backgroundColor={colors.white}
          color={colors.text}
          type="button"
          onClick={() => {
            backHandler();
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
            border: `1px solid ${colors.primaryColor}`,
          }}
          text={t('conclude')}
          backgroundColor={colors.white}
          color={colors.text}
          type="button"
          onClick={() => {
            handleEvaluatePoint();
          }}
        />
      </ButtonContainer>
    </Page>
  );
};

export default EvaluateSubplotScreen;
