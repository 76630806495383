import {
  PROPERTIES_START,
  PROPERTIES_SUCCESS,
  PROPERTIES_ERROR,
  PROPERTY_START,
  PROPERTY_SUCCESS,
  PROPERTY_ERROR,
  PROPERTY_RESET_STATE,
} from '../actions/types';

const initialState = {
  properties: [],
  property: null,
  loading: false,
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTIES_START:
      return {
        ...state,
        properties: [],
        loading: true,
      };
    case PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.properties,
        loading: false,
      };
    case PROPERTIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PROPERTY_START:
      return {
        ...state,
        property: null,
        loading: true,
      };
    case PROPERTY_SUCCESS:
      return {
        ...state,
        property: action.property,
        loading: false,
      };
    case PROPERTY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PROPERTY_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default propertiesReducer;
