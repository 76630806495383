import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Label,
  Error,
  Wrapper,
  InputWrapper,
} from './ValidatedAddPEForm.styles';
import { colors } from '../../constants/colors';
import CustomInputFull from '../CustomInputFull/CustomInputFull';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomButton from '../CustomButton/CustomButton';
import EvaluationIcon from '../../assets/icons/evaluation.svg';

const ValidatedAddPEForm = ({ latitude, longitude }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createPEClickHandler = useCallback(
    (values) => {
      console.log(values, dispatch);
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: {
      type: '',
      identification: '',
      latitude,
      longitude,
      area: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(t('required')),
      identification: Yup.string().required(t('required')),
      latitude: Yup.string().required(t('required')),
      longitude: Yup.string().required(t('required')),
      area: Yup.string().required(t('required')),
      username: Yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      createPEClickHandler(values);
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Wrapper>
        <InputWrapper>
          <Label htmlFor="type">{t('type')}</Label>
          <CustomSelect
            id="type"
            name="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
          />
          {formik.touched.type && formik.errors.type ? (
            <Error>{formik.errors.type}</Error>
          ) : null}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="identification">{t('identification')}</Label>
          <CustomInputFull
            id="identification"
            name="identification"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.identification}
          />
          {formik.touched.identification && formik.errors.identification ? (
            <Error>{formik.errors.identification}</Error>
          ) : null}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="latitude">{t('latitude')}</Label>
          <CustomInputFull
            id="latitude"
            name="latitude"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.latitude}
          />
          {formik.touched.latitude && formik.errors.latitude ? (
            <Error>{formik.errors.latitude}</Error>
          ) : null}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="longitude">{t('longitude')}</Label>
          <CustomInputFull
            id="longitude"
            name="longitude"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.longitude}
          />
          {formik.touched.longitude && formik.errors.longitude ? (
            <Error>{formik.errors.longitude}</Error>
          ) : null}
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="area">{t('area')}</Label>
          <CustomInputFull
            id="area"
            name="area"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.area}
          />
          {formik.touched.area && formik.errors.area ? (
            <Error>{formik.errors.area}</Error>
          ) : null}
        </InputWrapper>
      </Wrapper>

      <CustomButton
        style={{
          marginTop: 'auto',
          width: '100%',
          borderRadius: '5px',
          marginBottom: 90,
        }}
        icon={EvaluationIcon}
        text={t('evaluate')}
        backgroundColor={colors.primaryColor}
        type="submit"
      />
    </Container>
  );
};

export default ValidatedAddPEForm;
