import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper, Label } from './LevelD36A.styles';
import {
  D36A_RIPARIAN_COVERAGE_OPTIONS,
  FORM_D36A_PARAMETERS,
} from '../../../constants';
import { levelSuggestionD36A } from '../../../helpers/utils';
import PercentageRange from '../../PercentageRange/PercentageRange';
import CustomInputFull from '../../CustomInputFull/CustomInputFull';

const LevelD36A = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();

  const levelD36ARef = useRef({
    vegetation: 0,
    riparianGalleryCoverage: 0,
  });

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D36A_PARAMETERS.VEGETATION:
          levelD36ARef.current.vegetation = val;
          break;
        case FORM_D36A_PARAMETERS.RIPARIAN_GALLERY_COVERAGE:
          levelD36ARef.current.riparianGalleryCoverage = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD36A(
          levelD36ARef?.current?.vegetation,
          levelD36ARef?.current?.riparianGalleryCoverage,
        ),
      );
    },
    [levelD36ARef, setLevel],
  );

  const handleKeyDownEvent = useCallback((evt) => {
    if (['e', 'E', '+', '-'].includes(evt?.key)) evt.preventDefault();
  }, []);

  return (
    <FormWrapper>
      <div className="formRow">
        <Label htmlFor={`vegetation-${indicatorId}`}>
          {t('form-d36a.vegetation')}
        </Label>
        <CustomInputFull
          id={`vegetation-${indicatorId}`}
          name={`vegetation-${indicatorId}`}
          type="number"
          style={{ maxWidth: '75px' }}
          onKeyDown={(evt) => handleKeyDownEvent(evt)}
          onChange={(e) =>
            handleDataChange(e?.target?.value, FORM_D36A_PARAMETERS.VEGETATION)
          }
          value={levelD36ARef?.current?.vegetation}
        />
      </div>
      <PercentageRange
        title={t('form-d36a.riparian-gallery-coverage')}
        percentage={levelD36ARef.current.riparianGalleryCoverage}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D36A_PARAMETERS.RIPARIAN_GALLERY_COVERAGE)
        }
        options={D36A_RIPARIAN_COVERAGE_OPTIONS}
      />
      <hr />
    </FormWrapper>
  );
};

export default LevelD36A;
