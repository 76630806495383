/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { Icon } from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { extractPoints, extractPolyline } from '../../helpers/utils';
import 'leaflet/dist/leaflet.css';
import {
  Page,
  Container,
  ButtonContainer,
  PopupButtonContainer,
  SpinnerWrapper,
} from './SubplotMapScreen.styles';
import { getCurrentLocation } from '../../services/geolocation';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import Points from '../../data/pontos.kml';
import Subplot from '../../data/subparcela.kml';
import addIcon from '../../assets/icons/plus.svg';
import evaluation from '../../assets/icons/evaluation.svg';
import accessPointIcon from '../../assets/icons/access-point.svg';
import {
  getProperty,
  resetStateProperty,
} from '../../store/actions/properties';
import PopUp from '../../components/PopUp/PopUp';
import { getEvaluationData } from '../../services/local';

const SubplotMapScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const evaluationId = location?.state?.evaluationId;
  const params = useParams();
  const savedProperties = useSelector((state) => state.savedData.properties);
  const propertyDetails = useSelector((state) => state.properties.property);
  const property =
    propertyDetails || savedProperties.find((item) => item.id === params?.id);
  const selectedSubplot = property?.subplots?.find(
    (i) => i.id === params?.plot_id,
  );
  const storedEvaluationData = getEvaluationData();

  // States for Map ( Map | Markers | Polygons)
  const [markers, setMarkers] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertPoint, setOpenAlertPoint] = useState(false);
  const [pointEvaluatedFlag, setPointEvaluatedFlag] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [pointId, setPointId] = useState('');
  const [pointName, setPointName] = useState('');
  const [pointTypeId, setPointTypeId] = useState('');
  const [liveLocation, setLiveLocation] = useState(null);
  useEffect(() => {
    dispatch(getProperty(params?.id));
  }, [dispatch, params]);

  // get live location every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      getCurrentLocation().then((value) => setLiveLocation(value));
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!currentLocation) {
      getCurrentLocation().then((value) =>
        setCurrentLocation([value?.lat, value?.lng]),
      );
    }
  }, [currentLocation]);

  const customIcon = new Icon({
    iconUrl: accessPointIcon,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [38, 48],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const locationIcon = new Icon({
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  // Parses Kml local documents into Markers to be rendered offline instead of using google API
  const parsePoints = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polygons from de Document
    fileReader.onload = async (e) => {
      const result = await extractPoints(e.target.result);
      setMarkers(result?.markers);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Parses Kml local documents into Polyline to be rendered offline instead of using google API
  const parseSubplot = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polyline from de Document
    fileReader.onload = async (e) => {
      const result = await extractPolyline(e.target.result);
      setPolyline(result?.polyline);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Runs Only once at the screen rendering, fetchs the local document and parses it
  useEffect(() => {
    if (Points)
      fetch(Points)
        .then((r) => r.text())
        .then((text) => parsePoints(text));

    if (Subplot)
      fetch(Subplot)
        .then((r) => r.text())
        .then((text) => parseSubplot(text));
  }, []);

  const handleClick = (latlng) => {
    setSelectedPoint(latlng);
    setOpenAlert(true);
  };

  const handlePointClick = useCallback(
    (latlng, marker) => {
      const subplotPointId = property?.subplots
        ?.filter((subplot) => subplot?.id === selectedSubplot?.id)[0]
        ?.points?.filter(
          (point) => point?.identification === marker?.name,
        )[0]?.id;
      const pTypeId = property?.subplots
        ?.filter((subplot) => subplot?.id === selectedSubplot?.id)[0]
        ?.points?.filter((point) => point?.identification === marker?.name)[0]
        ?.pointType?.id;
      const isPointEvaluated =
        storedEvaluationData &&
        !!storedEvaluationData.filter(
          (point) => point?.pointId === subplotPointId,
        ).length;
      if (isPointEvaluated) {
        return setPointEvaluatedFlag(true);
      }
      setPointTypeId(pTypeId);
      setSelectedPoint(latlng);
      setPointId(subplotPointId);
      setPointName(marker?.name);
      setOpenAlertPoint(true);
    },
    [property, selectedSubplot, storedEvaluationData],
  );

  useEffect(() => {
    const fromLatLng = L.latLng(liveLocation);
    markers?.forEach((marker) => {
      const distance = fromLatLng?.distanceTo([marker?.lat, marker?.lng]);
      // live distance in meters
      if (distance < 2) {
        handlePointClick({ lat: marker?.lat, lng: marker?.lng }, marker);
      }
    });
  }, [liveLocation, currentLocation, markers, handlePointClick]);

  const backHandler = () => {
    dispatch(resetStateProperty());
    navigate(-1);
  };

  const close = () => {
    setOpenAlert(false);
  };

  const closePointAlert = () => {
    setOpenAlertPoint(false);
  };

  const closeEvaluatedFlag = () => {
    setPointEvaluatedFlag(false);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={selectedSubplot?.identification}
        subtitle={t('evaluate')}
      />
      <PopUp open={pointEvaluatedFlag} close={closeEvaluatedFlag}>
        <p className="popup-title">{t('already_evaluated_point')}</p>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            text={t('close')}
            backgroundColor={colors.primaryColor}
            type="button"
            onClick={() => {
              closeEvaluatedFlag();
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      <PopUp open={openAlertPoint} close={closePointAlert}>
        <p className="popup-title">{`${t('location_in')} ${pointName}`}</p>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={evaluation}
            text={t('start_evaluation')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.SUBPLOT_EVALUATE.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { pointId, pointName, evaluationId, pointTypeId } },
              );
            }}
          />
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={addIcon}
            text={t('update_point')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.UPDATE_POINT_SUBPLOT.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { selectedPos: selectedPoint, pointName } },
              );
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      <PopUp open={openAlert} close={close}>
        <PopupButtonContainer>
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={evaluation}
            text={t('add_pe')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.ADDAP_SUBPLOT.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { selectedPos: selectedPoint } },
              );
            }}
          />
          <CustomButton
            style={{
              width: '100%',
              borderRadius: '5px',
            }}
            icon={addIcon}
            text={t('update_ap')}
            backgroundColor={colors.primaryColor}
            type="submit"
            onClick={() => {
              navigate(
                generatePath(routes.UPDATE_AP_SUBPLOT.path, {
                  id: params?.id,
                  plot_id: params?.plot_id,
                }),
                { state: { selectedPos: selectedPoint } },
              );
            }}
          />
        </PopupButtonContainer>
      </PopUp>
      {currentLocation?.length ? (
        <Container>
          <MapContainer
            center={currentLocation}
            zoom={14}
            scrollWheelZoom={false}
            whenReady={(map) => {
              map.target.on('click', (e) => {
                handleClick(e?.latlng);
              });
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {polyline && <Polyline positions={polyline} />}
            <Marker position={currentLocation} icon={locationIcon} />
            {markers?.map((marker, index) => (
              <Marker
                key={index}
                position={[marker?.lat, marker?.lng]}
                icon={customIcon}
                eventHandlers={{
                  click: (e) => {
                    handlePointClick(e?.latlng, marker);
                  },
                }}
              />
            ))}
          </MapContainer>
        </Container>
      ) : (
        <SpinnerWrapper>
          <Spinner animation="border" variant="dark" />
        </SpinnerWrapper>
      )}
      <ButtonContainer>
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('add_pe')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.ADDAP_SUBPLOT.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
        <CustomButton
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          icon={addIcon}
          text={t('update_ap')}
          backgroundColor={colors.primaryColor}
          type="submit"
          onClick={() => {
            navigate(
              generatePath(routes.UPDATE_AP_SUBPLOT.path, {
                id: params?.id,
                plot_id: params?.plot_id,
              }),
            );
          }}
        />
      </ButtonContainer>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default SubplotMapScreen;
