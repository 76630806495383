import styled from 'styled-components';
import { colors } from '../../constants/colors';
import topBar from '../../assets/icons/TopBar/topBar.svg';
import { breakpoints } from '../../constants/breakpoints';

export const Container = styled.div`
  width: 100%;
  min-height: 150px;
  top: 0;
  left: 0;
  position: relative;
  background-color: ${colors.shadow2};
  z-index: 99;
  padding: 35px;
  background: url(${topBar});
  background-position-y: bottom;
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: ${breakpoints.tablet}) {
    min-height: 200px;
  }
`;

export const Back = styled.img`
  height: 24px;
  width: 12px;
  margin-right: 20px;
  background-color: transparent;
  cursor: pointer;
`;

export const Title = styled.div`
  color: ${colors.white};
  font-size: 24px;
`;

export const Subtitle = styled.div`
  color: ${colors.white};
  opacity: 50%;
  font-size: 16px;
  position: absolute;
  left: 68px;
  top: 35px;
`;

export const Logout = styled.button`
  margin-right: 20px;
  border-radius: 100%;
  border: none;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 5px ${colors.shadow2};
  background-color: ${colors.white};
  img {
    display: flex;
    margin: auto;
  }
`;

export const Statistics = styled.button`
  position: absolute;
  right: 35px;
  border-radius: 100%;
  border: none;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 5px ${colors.shadow2};
  background-color: ${colors.white};
  img {
    display: flex;
    margin: auto;
  }
`;

export const Download = styled.button`
  position: absolute;
  right: 35px;
  border-radius: 100%;
  border: none;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 5px ${colors.shadow2};
  background-color: ${colors.white};
  img {
    display: flex;
    margin: auto;
  }
  .storage {
    position: absolute;
    height: 16px;
    width: 16px;
    top: -5px;
    right: -5px;
  }
`;
export const Info = styled.button`
  position: absolute;
  right: 35px;
  border-radius: 100%;
  border: none;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 5px ${colors.shadow2};
  background-color: ${colors.white};
  img {
    display: flex;
    margin: auto;
  }
`;

export const Evaluation = styled.div`
  right: 35px;
  flex: 1;
  text-align: right;
  align-self: flex-start;
  .title {
    color: ${colors.white};
    opacity: 50%;
    font-size: 16px;
  }
  .content {
    color: ${colors.white};
    font-size: 16px;
  }
`;

export const Approved = styled.div`
  right: 35px;
  flex: 1;
  text-align: right;
  align-self: flex-start;
  .title {
    color: ${colors.white};
    opacity: 50%;
    font-size: 16px;
  }
  .content {
    color: ${colors.white};
    font-size: 16px;
  }
`;
