import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  .leaflet-container {
    border-radius: 25px;
    aspect-ratio: 1 / 1;
    max-height: 337px;
    height: auto;
    width: 100%;
    max-width: ${breakpoints.desktop};
    z-index: 98;
    margin-bottom: 105px;
  }
`;
