import {
  PROPERTY_SAVE_ERROR,
  PROPERTY_SAVE_START,
  PROPERTY_SAVE_SUCCESS,
} from '../actions/types';

const initialState = {
  properties: [],
  loading: false,
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTY_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case PROPERTY_SAVE_SUCCESS:
      return {
        ...state,
        properties: [...state.properties, action.property],
        loading: false,
      };
    case PROPERTY_SAVE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default propertiesReducer;
