import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 35px;
  height: 100%;
`;

export const Properties = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: ${colors.shadow1} 0px 0px 10px;
  border-radius: 12px;
  padding: 30px;
  font-size: 16px;
  margin-top: 30px;
  height: auto;
  z-index: 99;
  margin-bottom: 30px;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -60px;
  }
  .item {
    margin-bottom: 10px;
  }

  .label {
    color: ${colors.text};
    margin-right: 3px;
    font-weight: 900;
  }
`;

export const EvaluationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
  margin-bottom: 100px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.primaryColor};
  color: ${colors.white};
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 12px 12px 0 0;
`;

export const Evaluations = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: ${colors.shadow1} 0px 0px 10px;
  border-radius: 0 0 12px 12px;
  padding: 0 30px;
  font-size: 16px;
`;

export const EvaluationsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  tr:not(:last-child) {
    border-bottom: 1px solid ${colors.grey};
  }
  tr {
    text-align: left;
    padding: 30px 0;
    td {
      padding: 30px 0;
      color: ${colors.text};
      button {
        all: unset;
        cursor: pointer;
      }
      img {
        margin-right: 5px;
      }
      span {
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
`;

export const Property = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  border-bottom: 1px solid ${colors.grey};
  align-items: center;
  justify-content: space-between;
  .name {
    color: ${colors.text};
    font-size: 18px;
  }

  button {
    background-color: ${colors.white};
    border: none;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    box-shadow: 0 0 5px ${colors.shadow2};
    img {
      height: 16px;
      width: 16px;
      display: flex;
      margin: auto;
    }
  }
`;

export const PopupButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${breakpoints.desktop};
  padding: 35px;
  bottom: 70px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    color: ${colors.primaryColor};
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .body {
    color: ${colors.text};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
  }

  .comment {
    color: ${colors.text};
    font-size: 18px;
    font-weight: 600;
    opacity: 0.5;
    margin-bottom: 10px;
  }
`;
