/* eslint-disable no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page, Container, Card, Text } from './AddPEScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import ValidatedAddPEForm from '../../components/ValidatedAddPEForm/ValidatedAddPEForm';

const AddPEScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={t('add-new-pe')}
        subtitle={t('evaluation')}
      />

      <Container>
        <Card>
          <ValidatedAddPEForm />
        </Card>
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default AddPEScreen;
