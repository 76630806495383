import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 430px;
  margin: auto;
  padding: 0 35px;
`;

export const Logo = styled.img`
  margin-top: 89px;
  width: 100%;
  height: auto;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 135px;
  }
`;

export const FormContainer = styled.div`
  margin-top: -80px;
  background-color: ${colors.white};
  padding: 30px;
  width: 100%;
  border-radius: 15px;
  box-shadow: ${colors.shadow1} 0px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -100px;
  }
`;

export const Url = styled.a`
  color: ${colors.text};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: ${colors.primaryColor};
  }
`;
