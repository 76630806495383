import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper, Label } from './LevelD34.styles';
import {
  D34_DEGRADATION_OPTIONS,
  D34_VEGETATION_OPTIONS,
  FORM_D34_PARAMETERS,
} from '../../../constants';
import { levelSuggestionD34 } from '../../../helpers/utils';
import PercentageRange from '../../PercentageRange/PercentageRange';
import CustomInputFull from '../../CustomInputFull/CustomInputFull';

const LevelD34 = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();

  const levelD34Ref = useRef({
    nVegetation: '',
    selectionVegetation: 0,
    selectionDegradation: 0,
  });

  const handleKeyDownEvent = useCallback((evt) => {
    if (['e', 'E', '+', '-'].includes(evt?.key)) evt.preventDefault();
  }, []);

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D34_PARAMETERS.N_VEGETATION:
          levelD34Ref.current.nVegetation = val;
          break;
        case FORM_D34_PARAMETERS.SELECTION_VEGETATION:
          levelD34Ref.current.selectionVegetation = val;
          break;
        case FORM_D34_PARAMETERS.SELECTION_DEGRADATION:
          levelD34Ref.current.selectionDegradation = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD34(
          levelD34Ref?.current?.nVegetation,
          levelD34Ref?.current?.selectionVegetation,
          levelD34Ref?.current?.selectionDegradation,
        ),
      );
    },
    [levelD34Ref, setLevel],
  );

  return (
    <FormWrapper>
      <div className="formRow">
        <Label htmlFor={`vegetation-${indicatorId}`}>
          {t('form-d34.vegetation')}
        </Label>
        <CustomInputFull
          id={`vegetation-${indicatorId}`}
          name={`vegetation-${indicatorId}`}
          type="number"
          style={{ maxWidth: '75px' }}
          onKeyDown={(evt) => handleKeyDownEvent(evt)}
          onChange={(e) =>
            handleDataChange(e?.target?.value, FORM_D34_PARAMETERS.N_VEGETATION)
          }
          value={levelD34Ref?.current?.nVegetation}
        />
      </div>
      <PercentageRange
        title={t('form-d34.selection-vegetation')}
        percentage={levelD34Ref?.current?.selectionVegetation}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D34_PARAMETERS.SELECTION_VEGETATION)
        }
        options={D34_VEGETATION_OPTIONS}
      />
      <PercentageRange
        title={t('form-d34.selection-degradation')}
        percentage={levelD34Ref?.current?.selectionDegradation}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D34_PARAMETERS.SELECTION_DEGRADATION)
        }
        options={D34_DEGRADATION_OPTIONS}
      />
      <hr />
    </FormWrapper>
  );
};

export default LevelD34;
