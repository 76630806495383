import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from './HomeButton.styles';
import home from '../../assets/icons/home.svg';

const HomeButton = ({ routes }) => {
  const navigate = useNavigate();
  const homeHandler = () => {
    navigate(routes.PROPERTIES.path, { replace: true });
  };

  return (
    <Container>
      <Button type="button" onClick={() => homeHandler()}>
        <img src={home} alt="home" />
      </Button>
    </Container>
  );
};

export default HomeButton;
