/* eslint-disable no-unused-vars */
import axios, { Endpoints, getErrorMessage } from '../../services/api';
import { STATS, STATS_SUCCESS, STATS_ERROR } from './types';
import { HTTP_STATUS } from '../../constants';
import { getAuthToken } from '../../services/local';

export const getStats = () => async (dispatch) => {
  dispatch({ type: STATS });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  try {
    const response = await axios.get(Endpoints.GET_STATS, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: STATS_SUCCESS,
        data: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: STATS_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
