import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Page,
  Container,
  Properties,
  Property,
  InfoWrapper,
  ActionWrapper,
  NoResultsLabel,
} from './PropertiesScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import { getProperties } from '../../store/actions/properties';
import syncIcon from '../../assets/icons/synch.svg';
import nextIcon from '../../assets/icons/next.svg';
import searchIcon from '../../assets/icons/search.svg';
import CustomInputSearch from '../../components/CustomInputSearch/CustomInputSearch';

const PropertiesScreen = (props) => {
  const { routes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onlineList = useSelector((state) => state.properties.properties);
  const savedProperties = useSelector((state) => state.savedData.properties);
  const propertiesList = onlineList || savedProperties;
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    dispatch(getProperties());
  }, [dispatch]);

  useEffect(() => {
    setFilteredProperties(propertiesList);
  }, [propertiesList]);

  const propertyClickHandler = (propertyId) => {
    navigate(generatePath(routes.PROPERTYMAP.path, { id: propertyId }));
  };

  const handleSearch = useCallback(
    (value) => {
      if (value) {
        setSearch(true);
        setFilteredProperties(
          propertiesList?.filter((property) =>
            property?.name.toLowerCase().includes(value.toLowerCase()),
          ),
        );
      } else {
        setSearch(false);
        setFilteredProperties(propertiesList);
      }
    },
    [propertiesList],
  );

  const handleSyncBtn = (propertyId) => {
    navigate(
      generatePath(routes.SUBPLOTS_IN_EVALUATION.path, { id: propertyId }),
    ); // TEMP
  };

  return (
    <Page>
      <TopBar
        title={t('beneficiaries')}
        logOut
        statistics={() => navigate(generatePath(routes.STATISTICS.path))}
      />
      <Container>
        <CustomInputSearch
          type="text"
          icon={searchIcon}
          placeholder={t('search')}
          onChange={(e) => handleSearch(e?.target?.value)}
        />
        {filteredProperties?.length ? (
          <Properties>
            {filteredProperties?.map((property) => (
              <Property
                key={property?.propertyId || property?.id}
                name="property"
                onClick={() =>
                  propertyClickHandler(property?.propertyId || property?.id)
                }
              >
                <InfoWrapper>
                  <div className="name">{property?.name}</div>
                  <div className="label">{t('producer_name')}</div>
                  <div className="field">
                    {property?.ownerName || property?.user?.name}
                  </div>
                  <div className="label">{t('representative_name')}</div>
                  <div className="field">{property?.agentName}</div>
                  <div className="label">{t('representative_phone')}</div>
                  <div className="field">
                    <a href={`tel:${property?.agentMobilePhone}`}>
                      {property?.agentMobilePhone}
                    </a>
                  </div>
                  <div className="label">{t('access_coords')}</div>
                  <div className="field">
                    {property?.latitudeAccess
                      ?.concat('; ')
                      .concat(property?.longitudeAccess) ||
                      property?.lat?.concat('; ').concat(property?.lng)}
                  </div>
                </InfoWrapper>
                <ActionWrapper>
                  <button
                    type="button"
                    name="update"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSyncBtn(property?.propertyId || property?.id);
                    }}
                  >
                    <img src={syncIcon} alt="synchronize" />
                  </button>
                  <img className="click" src={nextIcon} alt="synchronize" />
                </ActionWrapper>
              </Property>
            ))}
          </Properties>
        ) : null}
        {search && !filteredProperties?.length && (
          <NoResultsLabel className="no-results">
            {t('no-results')}
          </NoResultsLabel>
        )}
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default PropertiesScreen;
