/* eslint-disable no-unused-vars */
import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  PROPERTY_SAVE_START,
  PROPERTY_SAVE_SUCCESS,
  PROPERTY_SAVE_ERROR,
} from './types';
import { HTTP_STATUS } from '../../constants';

import { getAuthToken } from '../../services/local';

export const saveProperty = (id) => async (dispatch) => {
  dispatch({ type: PROPERTY_SAVE_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const url = Endpoints.PROPERTY.concat('?propertyId=').concat(id);
  try {
    const response = await axios.get(url, config);
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: PROPERTY_SAVE_SUCCESS,
        property: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.message);
    }
  } catch (error) {
    dispatch({ type: PROPERTY_SAVE_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
