import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.p`
  color: ${colors.primaryColor};
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 30px;
`;

export const Description = styled.p`
  color: ${colors.text};
  font-size: 18px;
  margin-bottom: 30px;
`;

export const SubTitle = styled.p`
  color: ${colors.primaryColor};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const TableHeader = styled.div`
  width: 100%;
  color: ${colors.white};
  padding: 15px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;

  &.red {
    background-color: ${colors.pomegranate};
  }
  &.orange {
    background-color: ${colors.orange};
  }
  &.yellow {
    background-color: ${colors.yellow};
  }
  &.green {
    background-color: ${colors.mantis};
  }
`;

export const TableWrapper = styled.div`
  border: 1px solid ${colors?.gray};
  color: ${colors?.text};
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
`;
