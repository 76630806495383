import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import { Icon } from 'leaflet';
import { Wrapper } from './SmallMap.styles';
import 'leaflet/dist/leaflet.css';
import accessPointIcon from '../../assets/icons/access-point.svg';
import { extractPoints, extractPolyline } from '../../helpers/utils';

const SmallMap = ({ center, points, subplot }) => {
  const [markers, setMarkers] = useState(null);
  const [polyline, setPolyline] = useState(null);
  // Markers: https://github.com/pointhi/leaflet-color-markers
  const customIcon = new Icon({
    iconUrl: accessPointIcon,
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [38, 48],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const locationIcon = new Icon({
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
    shadowUrl:
      'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  // Parses Kml local documents into Markers to be rendered offline instead of using google API
  const parsePoints = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polygons from de Document
    fileReader.onload = async (e) => {
      const result = await extractPoints(e.target.result);
      setMarkers(result?.markers);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Parses Kml local documents into Polyline to be rendered offline instead of using google API
  const parseSubplot = (file) => {
    const fileReader = new FileReader();

    // Sets file onload that call function from utils that does the parsing and sets Markers and Polyline from de Document
    fileReader.onload = async (e) => {
      const result = await extractPolyline(e.target.result);
      setPolyline(result?.polyline);
    };

    // Opens the file to read as text converting it into a Blob of type "text/xml"
    fileReader.readAsText(
      new Blob([file], {
        type: 'text/xml',
      }),
    );
  };

  // Runs Only once at the screen rendering, fetchs the local document and parses it
  useEffect(() => {
    if (points)
      fetch(points)
        .then((r) => r.text())
        .then((text) => parsePoints(text));

    if (subplot)
      fetch(subplot)
        .then((r) => r.text())
        .then((text) => parseSubplot(text));
  }, [points, subplot]);

  return (
    <Wrapper>
      {center && (
        <MapContainer
          center={[center?.lat, center?.lng]}
          zoom={16}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {polyline && <Polyline positions={polyline} />}
          <Marker
            eventHandlers={[center?.lat, center?.lng]}
            position={center}
            icon={locationIcon}
          />
          {markers?.map((marker, index) => (
            <Marker
              key={index}
              eventHandlers={[marker?.lat, marker?.lng]}
              position={[marker?.lat, marker?.lng]}
              icon={customIcon}
            />
          ))}
        </MapContainer>
      )}
    </Wrapper>
  );
};

export default SmallMap;
