import React, { useState, useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Page, Container, SubPlots, SubPlot } from './SubPlotsScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import HomeButton from '../../components/HomeButton/HomeButton';
import PopUp from '../../components/PopUp/PopUp';
import AlertInfo from '../../components/AlertInfo/AlertInfo';
import coordinates from '../../assets/icons/coordinates.svg';
import { getProperty } from '../../store/actions/properties';
import { EVALUATION_STATUS } from '../../constants';
import { normalizeStr } from '../../helpers/utils';

const SubPlotsScreen = (props) => {
  const { routes } = props;
  const navigate = useNavigate();
  const params = useParams();
  const savedProperties = useSelector((state) => state.savedData.properties);
  const onlineProperty = useSelector((state) => state.properties.property);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const property =
    onlineProperty || savedProperties.find((item) => item.id === params?.id);

  const subplotDate = (date) => moment(new Date(date)).format('DD/MM/YYYY');

  useEffect(() => {
    dispatch(getProperty(params?.id));
  }, [dispatch, params]);

  const subPlotClickHandler = (subPlotId, evaluation) => {
    const evaluationId = evaluation?.filter(
      (e) =>
        normalizeStr(e?.status) ===
        normalizeStr(EVALUATION_STATUS?.IN_EVALUATION),
    )[0]?.id;
    navigate(
      generatePath(routes.SUBPLOT.path, {
        id: params?.id,
        plot_id: subPlotId,
      }),
      { state: { evaluationId } },
    );
  };

  const backHandler = () => {
    navigate(-1);
  };

  const close = () => {
    setOpenAlert(false);
  };

  return (
    <Page>
      <TopBar
        back={backHandler}
        title={property?.name}
        info={() => setOpenAlert(true)}
      />
      <PopUp open={openAlert} close={close}>
        <AlertInfo />
      </PopUp>
      <Container>
        <SubPlots>
          {property?.subplots?.map((subPlot, index) => (
            <SubPlot
              key={index}
              onClick={() =>
                subPlotClickHandler(subPlot?.id, subPlot?.evaluations)
              }
            >
              <div className="subplot-info">
                <div className="name-location">
                  <span className="name">{subPlot?.identification}</span>
                  <span className="coords">
                    <img src={coordinates} alt="coordinates" />
                    {`${subPlot?.lat}; ${subPlot?.lng}`}
                  </span>
                </div>
                <span className="date">
                  {subplotDate(subPlot?.lastEvaluationDate)}
                </span>
              </div>
            </SubPlot>
          ))}
        </SubPlots>
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default SubPlotsScreen;
