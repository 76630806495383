import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const PercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .label {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.text};
  }
  .percentageOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 100%;
      height: 50px;
      max-width: 80px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: ${colors.white};
      background-color: ${colors.primaryColor};

      :not(.highlight) {
        color: ${colors.text};
        border: 1px solid ${colors.primaryColor};
        background-color: ${colors.white};
      }
    }
    .no-styles {
      all: unset;
    }
  }
`;
