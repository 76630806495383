import {
  GENERAL_HELP,
  GENERAL_HELP_SUCCESS,
  GENERAL_HELP_ERROR,
} from '../actions/types';

const initialState = {
  generalHelp: [],
  loading: false,
};

const helpersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_HELP:
      return {
        ...state,
        generalHelp: [],
        loading: true,
      };
    case GENERAL_HELP_SUCCESS:
      return {
        ...state,
        generalHelp: action.generalHelp,
        loading: false,
      };
    case GENERAL_HELP_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default helpersReducer;
