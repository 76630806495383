import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from './LevelD35.styles';
import {
  D35_SLOPE_OPTIONS,
  D35_VEGETATION_OPTIONS,
  FORM_D35_PARAMETERS,
} from '../../../constants';
import { levelSuggestionD35 } from '../../../helpers/utils';
import PercentageRange from '../../PercentageRange/PercentageRange';
import ToggleButton from '../../ToggleButton/ToggleButton';

const LevelD35 = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();

  const levelD35Ref = useRef({
    bushes: false,
    vegetation: false,
    selectionVegetation: 0,
    selectionSlope: 0,
  });

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D35_PARAMETERS.BUSHES:
          levelD35Ref.current.bushes = val;
          break;
        case FORM_D35_PARAMETERS.VEGETATION:
          levelD35Ref.current.vegetation = val;
          break;
        case FORM_D35_PARAMETERS.SELECTION_VEGETATION:
          levelD35Ref.current.selectionVegetation = val;
          break;
        case FORM_D35_PARAMETERS.SELECTION_SLOPE:
          levelD35Ref.current.selectionSlope = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD35(
          levelD35Ref?.current?.bushes,
          levelD35Ref?.current?.vegetation,
          levelD35Ref?.current?.selectionVegetation,
          levelD35Ref?.current?.selectionSlope,
        ),
      );
    },
    [levelD35Ref, setLevel],
  );

  return (
    <FormWrapper>
      <div className="formRow">
        <span className="label">{t('form-d35.bushes')}</span>
        <ToggleButton
          id={`bushes-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD35Ref?.current?.bushes}
          onClick={(val) => handleDataChange(val, FORM_D35_PARAMETERS.BUSHES)}
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d35.vegetation')}</span>
        <ToggleButton
          id={`vegetation-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD35Ref?.current?.vegetation}
          onClick={(val) =>
            handleDataChange(val, FORM_D35_PARAMETERS.VEGETATION)
          }
        />
      </div>
      <PercentageRange
        title={t('form-d35.vegetation-coverage')}
        percentage={levelD35Ref.current.selectionVegetation}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D35_PARAMETERS.SELECTION_VEGETATION)
        }
        options={D35_VEGETATION_OPTIONS}
      />
      <PercentageRange
        title={t('form-d35.slope-coverage')}
        percentage={levelD35Ref.current.selectionSlope}
        setPercentage={(val) =>
          handleDataChange(val, FORM_D35_PARAMETERS.SELECTION_SLOPE)
        }
        options={D35_SLOPE_OPTIONS}
      />
      <hr />
    </FormWrapper>
  );
};

export default LevelD35;
