import styled from 'styled-components';
import { colors } from '../../constants/colors';
import chevronDown from '../../assets/icons/chevron_down.svg';
import chevronUp from '../../assets/icons/chevron_up.svg';

export const Accordion = styled.div`
  .accordion__input {
    display: none;
  }

  .accordion__input:checked ~ .accordion__content {
    display: block;
    opacity: 1;
  }

  .accordion__input:checked + label::after {
    content: url(${chevronDown});
  }
`;

export const FormHeader = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  width: 100%;
  background-color: ${(props) => props.color};
  color: ${colors.white};
  padding: 20px 15px;
  font-size: 16px;
  position: relative;
  &:after {
    content: url(${chevronUp});
  }
`;

export const FormWrapper = styled.div`
  display: none;
  opacity: 0;
  border: 1px solid ${(props) => props.color};
  padding: 20px 15px;
  flex-direction: column;
  gap: 20px;
  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .label {
      font-size: 16px;
      font-weight: 600;
      margin-top: auto;
      margin-bottom: auto;
      color: ${colors.text};
    }
  }
`;
