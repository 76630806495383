/* eslint-disable no-unused-vars */
import axios, { Endpoints, getErrorMessage } from '../../services/api';
import {
  PROPERTIES_START,
  PROPERTIES_SUCCESS,
  PROPERTIES_ERROR,
  PROPERTY_START,
  PROPERTY_SUCCESS,
  PROPERTY_ERROR,
  PROPERTY_RESET_STATE,
  UPDATE_PROPERTY_AP_START,
  UPDATE_PROPERTY_AP_SUCCESS,
  UPDATE_PROPERTY_AP_ERROR,
} from './types';
import { HTTP_STATUS } from '../../constants';

import { getAuthToken } from '../../services/local';

export const getProperties = () => async (dispatch) => {
  dispatch({ type: PROPERTIES_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  try {
    const response = await axios.get(Endpoints.PROPERTIES, config);
    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: PROPERTIES_SUCCESS,
        properties: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: PROPERTIES_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const getProperty = (id) => async (dispatch) => {
  dispatch({ type: PROPERTY_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };
  const url = Endpoints.PROPERTY.concat('?propertyId=').concat(id);
  try {
    const response = await axios.get(url, config);
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: PROPERTY_SUCCESS,
        property: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: PROPERTY_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};

export const resetStateProperty = () => (dispatch) => {
  dispatch({ type: PROPERTY_RESET_STATE });
};

export const updatePropertyAP = (plotId, values) => async (dispatch) => {
  dispatch({ type: UPDATE_PROPERTY_AP_START });
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  const body = {
    propertyId: plotId,
    latitudeAccess: values?.latitude?.toString(),
    longitudeAccess: values?.longitude?.toString(),
  };

  try {
    const response = await axios.post(
      Endpoints.UPDATE_PROPERTY_AP,
      body,
      config,
    );
    const statusCode = response.status;
    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: UPDATE_PROPERTY_AP_SUCCESS,
        property: response?.data?.dataObject?.status,
      });
      return Promise.resolve(response?.data?.dataObject?.status);
    }
  } catch (error) {
    dispatch({ type: UPDATE_PROPERTY_AP_ERROR });

    return Promise.reject(getErrorMessage(error, 'something_wrong'));
  }
};
