import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Container, StatContainer } from './StatisticsScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import { getStats } from '../../store/actions/stats';
import HomeButton from '../../components/HomeButton/HomeButton';

const StatisticsScreen = ({ routes }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statsData = useSelector((state) => state?.stats?.stats);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  const statsStructure = [
    { label: t('stats.total-explorations'), value: statsData?.totalProperties },
    { label: t('stats.total-subplots'), value: statsData?.totalSubPlots },
    {
      label: t('stats.subplots-to-evaluate'),
      value: statsData?.totalSubPlotsToEvaluate,
    },
    {
      label: t('stats.evaluated-subplots'),
      value: statsData?.totalEvaluatedSubPlots,
    },
  ];

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopBar back={backHandler} title={t('stats.title')} />
      <Container>
        {statsStructure?.map((stat, index) => (
          <StatContainer key={index}>
            <span className="title">{stat?.label}</span>
            <span className="value">{stat?.value}</span>
          </StatContainer>
        ))}
      </Container>
      <HomeButton routes={routes} />
    </Page>
  );
};

export default StatisticsScreen;
