import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../services/local';

const PrivateRoute = ({ routes, children }) => {
  if (!isAuthenticated()) {
    return <Navigate to={routes.LOGIN.path} replace />;
  }
  return children;
};
export default PrivateRoute;
