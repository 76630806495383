import React from 'react';
import { Textarea } from './CustomTextarea.styles';
import closeIcon from '../../assets/icons/close.svg';

const CustomTextarea = ({ close, value, onChange, rows }) => (
  <Textarea>
    {!!close && (
      <button type="button" className="close" onClick={() => close()}>
        <img src={closeIcon} alt="close" />
      </button>
    )}
    <textarea value={value} onChange={onChange} rows={rows} />
  </Textarea>
);
export default CustomTextarea;
