import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormWrapper } from './LevelD33.styles';
import ToggleButton from '../../ToggleButton/ToggleButton';
import { FORM_D33_PARAMETERS } from '../../../constants';
import { levelSuggestionD33 } from '../../../helpers/utils';

const LevelD33 = ({ setLevel, indicatorId }) => {
  const { t } = useTranslation();
  const levelD33Ref = useRef({
    slits: false,
    succulentPlants: false,
    fetuses: false,
    mosses: false,
    lichens: false,
  });

  const handleDataChange = useCallback(
    (val, key) => {
      switch (key) {
        case FORM_D33_PARAMETERS.SLITS:
          levelD33Ref.current.slits = val;
          break;
        case FORM_D33_PARAMETERS.SUCCULENT_PLANTS:
          levelD33Ref.current.succulentPlants = val;
          break;
        case FORM_D33_PARAMETERS.FETUSES:
          levelD33Ref.current.fetuses = val;
          break;
        case FORM_D33_PARAMETERS.MOSSES:
          levelD33Ref.current.mosses = val;
          break;
        case FORM_D33_PARAMETERS.LICHENS:
          levelD33Ref.current.lichens = val;
          break;
        default:
          break;
      }

      setLevel(
        levelSuggestionD33(
          levelD33Ref?.current?.slits,
          levelD33Ref?.current?.succulentPlants,
          levelD33Ref?.current?.fetuses,
          levelD33Ref?.current?.mosses,
          levelD33Ref?.current?.lichens,
        ),
      );
    },
    [levelD33Ref, setLevel],
  );

  return (
    <FormWrapper>
      <div className="formRow">
        <span className="label">{t('form-d33.slits')}</span>
        <ToggleButton
          id={`slits-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD33Ref?.current?.slits}
          onClick={(val) => handleDataChange(val, FORM_D33_PARAMETERS.SLITS)}
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d33.succulentPlants')}</span>
        <ToggleButton
          id={`succulentPlants-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD33Ref?.current?.succulentPlants}
          onClick={(val) =>
            handleDataChange(val, FORM_D33_PARAMETERS.SUCCULENT_PLANTS)
          }
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d33.fetuses')}</span>
        <ToggleButton
          id={`fetuses-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD33Ref?.current?.fetuses}
          onClick={(val) => handleDataChange(val, FORM_D33_PARAMETERS.FETUSES)}
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d33.mosses')}</span>
        <ToggleButton
          id={`mosses-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD33Ref?.current?.mosses}
          onClick={(val) => handleDataChange(val, FORM_D33_PARAMETERS.MOSSES)}
        />
      </div>
      <div className="formRow">
        <span className="label">{t('form-d33.lichens')}</span>
        <ToggleButton
          id={`lichens-${indicatorId}`}
          checkedOption={t('yes')}
          uncheckedOption={t('no')}
          toggled={levelD33Ref?.current?.lichens}
          onClick={(val) => handleDataChange(val, FORM_D33_PARAMETERS.LICHENS)}
        />
      </div>
      <hr />
    </FormWrapper>
  );
};

export default LevelD33;
