import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  margin: auto;
  background-color: ${colors.transparent};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;
  height: calc(100% - 150px);
  .leaflet-container {
    height: calc(100vh);
    width: 100vw;
    position: absolute !important;
    max-width: ${breakpoints.desktop};
    z-index: 98;
    top: 0;
  }
  .leaflet-control-container .leaflet-top {
    top: 150px;
  }

  .leaflet-tooltip-pane .leaflet-tooltip {
    background: none !important;
    border: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    font-size: 20px;

    &::before {
      content: unset !important;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${breakpoints.desktop};
  padding: 35px;
  bottom: 70px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.label`
  font-weight: 900;
  color: ${colors.primaryColor};
  font-size: 24px;
  text-align: center;
`;

export const Text = styled.label`
  font-weight: 900;
  color: ${colors.text};
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
`;
