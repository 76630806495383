import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .levelOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;

      .optionContainer {
        border: none;
        width: 100%;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 50px;
        max-width: 80px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: ${colors.white};

        :not(.highlight) {
          opacity: 0.5;
        }

        &.red {
          background: ${colors.pomegranate};
        }
        &.orange {
          background: ${colors.orange};
        }
        &.yellow {
          background: ${colors.yellow};
        }
        &.green {
          background: ${colors.mantis};
        }
      }
      .description {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.text};
        text-align: center;
      }
    }
  }
`;

export const InfoButton = styled.button`
  all: unset;
  display: flex;
  width: 100%;
  justify-content: center;
}
`;
