import React, { useState } from 'react';
import ToggleBtn from './ToggleButton.styles';

const ToggleButton = ({
  id,
  checkedOption,
  uncheckedOption,
  toggled,
  onClick,
}) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <ToggleBtn>
      <p>{checkedOption}</p>
      <label htmlFor={id}>
        <input
          id={id}
          name={id}
          type="checkbox"
          defaultChecked={isToggled}
          onClick={callback}
        />
        <span />
      </label>
      <p>{uncheckedOption}</p>
    </ToggleBtn>
  );
};

export default ToggleButton;

/*
USAGE
<div className="formRow">
<span className="label">{t('form-d33.slits')}</span>
<ToggleButton
  id="slits"
  checkedOption={t('yes')}
  uncheckedOption={t('no')}
  toggled={formData?.slits}
  onClick={(val) =>
    handleDataChange(val, FORM_D33_PARAMETERS.SLITS)
  }
/>
</div>

*/
