import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import PropertiesScreen from '../screens/PropertiesScreen/PropertiesScreen';
import SubPlotsScreen from '../screens/SubPlotsScreen/SubPlotsScreen';
import MapScreen from '../screens/MapScreen/MapScreen';
import PropertyMapScreen from '../screens/PropertyMapScreen/PropertyMapScreen';
import SubPlotScreen from '../screens/SubPlotScreen/SubPlotScreen';
import AddPEScreen from '../screens/AddPEScreen/AddPEScreen';
import AddAPScreen from '../screens/AddAPScreen/AddAPScreen';
import SubPlotsInEvaluationScreen from '../screens/SubPlotsInEvaluationScreen/SubPlotsInEvaluationScreen';
import EvaluateSubplotScreen from '../screens/EvaluateSubplotScreen/EvaluateSubplotScreen';
import StatisticsScreen from '../screens/StatisticsScreen/StatisticsScreen';
import SubplotMapScreen from '../screens/SubplotMapScreen/SubplotMapScreen';
import UpdateSubplotAPScreen from '../screens/UpdateSubplotAPScreen/UpdateSubplotAPScreen';
import UpdatePropertyAPScreen from '../screens/UpdatePropertyAPScreen/UpdatePropertyAPScreen';
import UpdatePointScreen from '../screens/UpdatePointScreen/UpdatePointScreen';

const history = createBrowserHistory({ forceRefresh: true });

const routes = {
  HOME: {
    path: '/',
    element: PropertiesScreen,
    private: true,
    index: true,
  },
  PROPERTIES: {
    path: '/properties',
    element: PropertiesScreen,
    private: true,
    index: false,
  },
  MAP: {
    path: '/property/:id/subplot/:plot_id/map',
    element: MapScreen,
    private: true,
    index: false,
  },
  PROPERTYMAP: {
    path: '/property/:id',
    element: PropertyMapScreen,
    private: true,
    index: false,
  },
  SUBPLOT: {
    path: '/property/:id/subplot/:plot_id',
    element: SubPlotScreen,
    private: true,
    index: false,
  },
  SUBPLOT_MAP: {
    path: '/property/:id/subplot/:plot_id/evaluate',
    element: SubplotMapScreen,
    private: true,
    index: false,
  },
  SUBPLOTS: {
    path: '/property/:id/subplots',
    element: SubPlotsScreen,
    private: true,
    index: false,
  },
  ADDPE: {
    path: '/property/:id/subplots/:plot_id/addpe/',
    element: AddPEScreen,
    private: true,
    index: false,
  },
  ADDAP_PROP: {
    path: '/property/:id/addap/',
    element: AddAPScreen,
    private: true,
    index: false,
  },
  ADDAP_SUBPLOT: {
    path: '/property/:id/subplots/:plot_id/addap/',
    element: AddAPScreen,
    private: true,
    index: false,
  },
  UPDATE_POINT_SUBPLOT: {
    path: '/property/:id/subplots/:plot_id/update-point/',
    element: UpdatePointScreen,
    private: true,
    index: false,
  },
  UPDATE_AP_PROPERTY: {
    path: '/property/:id/update-ap/',
    element: UpdatePropertyAPScreen,
    private: true,
    index: false,
  },
  UPDATE_AP_SUBPLOT: {
    path: '/property/:id/subplots/:plot_id/update-ap/',
    element: UpdateSubplotAPScreen,
    private: true,
    index: false,
  },
  SUBPLOTS_IN_EVALUATION: {
    path: '/subplots-evaluation/:id',
    element: SubPlotsInEvaluationScreen,
    private: true,
    index: false,
  },
  SUBPLOT_EVALUATE: {
    path: '/evaluate-subplot/:plot_id',
    element: EvaluateSubplotScreen,
    private: true,
    index: false,
  },
  STATISTICS: {
    path: '/statistics',
    element: StatisticsScreen,
    private: true,
    index: false,
  },
  LOGIN: {
    path: '/login',
    element: LoginScreen,
    private: false,
    index: false,
  },
};

const GlobalRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        {Object.values(routes).map((route) => {
          if (route.private) {
            return (
              <Route
                exact
                key={route.path}
                path={route.path}
                index={route.index}
                element={
                  <PrivateRoute routes={routes} history={history}>
                    <route.element routes={routes} history={history} />
                  </PrivateRoute>
                }
              />
            );
          }
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              index={route.index}
              element={<route.element routes={routes} history={history} />}
            />
          );
        })}
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
};

export default GlobalRoutes;
