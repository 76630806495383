import {
  SUBPLOTS_IN_EVALUATION,
  SUBPLOTS_IN_EVALUATION_SUCCESS,
  SUBPLOT_ANALYSYS_DATA,
  SUBPLOTS_IN_EVALUATION_ERROR,
  SUBPLOTS,
  SUBPLOTS_SUCCESS,
  SUBPLOTS_ERROR,
  SUBPLOT,
  SUBPLOT_SUCCESS,
  SUBPLOT_ERROR,
} from '../actions/types';

const initialState = {
  subPlotsInEvaluation: [],
  analysysData: {},
  subplots: [],
  supbplot: null,
  loading: false,
};

const subplotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBPLOTS_IN_EVALUATION:
      return {
        ...state,
        subPlotsInEvaluation: [],
        loading: true,
      };
    case SUBPLOTS_IN_EVALUATION_SUCCESS:
      return {
        ...state,
        subPlotsInEvaluation: action.subPlotsInEvaluation,
        loading: false,
      };
    case SUBPLOT_ANALYSYS_DATA:
      return {
        ...state,
        analysysData: action.analysysData,
      };
    case SUBPLOTS_IN_EVALUATION_ERROR:
      return {
        ...state,
        subPlotsInEvaluation: [],
        loading: false,
      };
    case SUBPLOTS:
      return {
        ...state,
        subplots: [],
        loading: true,
      };
    case SUBPLOTS_SUCCESS:
      return {
        ...state,
        subplots: action.subplots,
        loading: false,
      };
    case SUBPLOTS_ERROR:
      return {
        ...state,
        subplots: [],
        loading: false,
      };
    case SUBPLOT:
      return {
        ...state,
        subplot: null,
        loading: true,
      };
    case SUBPLOT_SUCCESS:
      return {
        ...state,
        subplot: action.subplot,
        loading: false,
      };
    case SUBPLOT_ERROR:
      return {
        ...state,
        subplot: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default subplotsReducer;
