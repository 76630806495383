import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { breakpoints } from '../../constants/breakpoints';

export const Page = styled.div`
  max-width: ${breakpoints.desktop};
  height: 100vh;
  margin: auto;
  background-color: ${colors.transparent};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 35px;
  margin-top: 100px;
`;

export const SubPlots = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SubPlot = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  border-bottom: 1px solid ${colors.grey};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .name {
    color: ${colors.text};
    font-size: 18px;
  }

  .subplot-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .name-location {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .coords {
      display: flex;
      gap: 8px;
    }
  }

  .coords,
  .date {
    font-size: 14px;
    color: ${colors.text};
    opacity: 0.5;
  }

  button {
    background-color: ${colors.white};
    border: none;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    box-shadow: 0 0 5px ${colors.shadow2};
    img {
      height: 16px;
      width: 16px;
      display: flex;
      margin: auto;
    }
  }
`;
