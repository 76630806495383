import { STATS, STATS_SUCCESS, STATS_ERROR } from '../actions/types';

const initialState = {
  stats: {},
  loading: false,
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS:
      return {
        ...state,
        stats: {},
        loading: true,
      };
    case STATS_SUCCESS:
      return {
        ...state,
        stats: action?.data,
        loading: false,
      };
    case STATS_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default statsReducer;
