import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Textarea = styled.div`
  width: 100%;
  position: relative;
  textarea {
    all: unset;
    resize: none;
    width: inherit;
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    font-size: 16px;
    padding: 5px;
    box-sizing: border-box;
    opacity: 0.7;
    &:focus {
      opacity: 1;
    }
  }
  .close {
    width: 10px;
    position: absolute;
    right: 10px;
    top: 3px;
    z-index: 1;
    img {
      width: inherit;
    }
  }
`;
